import React, { useCallback, useMemo, useState, memo } from "react";

import { Button, Grid, Typography } from "@mui/material";

import { UploadButton } from "./UploadButton";
import { toast } from "react-toastify";

const UTILITYBILLS_MAX = 1;

export const UtilityBillsForm = memo(
  ({ requestState, closeForm, setRequestState, setErrors, theme }) => {
    const [existedUtilityBills, setExistedUtilityBills] = useState(
      requestState.utilityBills || []
    );
    const [utilityBillFiles, setUtilityBillFiles] = useState(
      requestState.utilityBillFiles || []
    );

    const allUtilityBills = useMemo(
      () => utilityBillFiles.concat(existedUtilityBills),
      [utilityBillFiles, existedUtilityBills]
    );

    const handleUploadBillImage = useCallback(
      (e, type) => {
        const { files } = e.target;

        if (allUtilityBills.length + files.length > UTILITYBILLS_MAX) {
          return toast.info(`You can upload up to ${UTILITYBILLS_MAX} image`);
        }
        let utilityBillUploads = [...utilityBillFiles];

        Object.values(files).forEach(async (file) => {
          utilityBillUploads.push({
            id: Math.random().toString(),
            data: file,
            type,
          });
        });

        setUtilityBillFiles(utilityBillUploads);
      },
      [allUtilityBills]
    );

    const handleDeleteUtilityBill = (imageId, imageURL) => {
      if (imageURL) {
        const updatedExistedBills = existedUtilityBills.filter(
          (image) => image.url !== imageURL
        );
        return setExistedUtilityBills(updatedExistedBills);
      } else if (imageId) {
        const updatedBillsFiles = utilityBillFiles.filter(
          (image) => image.id !== imageId
        );
        return setUtilityBillFiles(updatedBillsFiles);
      }
    };

    const submitUtilityBills = () => {
      if (existedUtilityBills.length === UTILITYBILLS_MAX) {
        return closeForm();
      }

      if (allUtilityBills.length < UTILITYBILLS_MAX) {
        return toast.info(`Please add paystubs`);
      }

      if (utilityBillFiles.length) {
        setRequestState((prevState) => ({
          ...prevState,
          utilityBills: existedUtilityBills,
          utilityBillFiles,
        }));
        setErrors((prevState) => ({
          ...prevState,
          utilityBills: false,
          utilityBillFiles: false,
        }));

        closeForm();
      } else {
        toast("Please add paystubs");
      }
    };
    return (
      <Grid
        container
        sx={{
          flexDirection: "column",
          gap: "7px",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          width: "100%",
          border: "1px solid rgba(66, 103, 178,0.3)",
          boxShadow: "0px 1px 6px 0px #7C9AEA",
          borderRadius: "6px",
          padding: "1em",
        }}
      >
        <Typography fontSize={18}>
          Utility bill{" "}
          <span style={{ fontWeight: "bold" }}>
            has to be within 31 days old, contain an address
          </span>
          , and can not be a cell phone bill.
        </Typography>
        <Grid
          container
          item
          sx={{
            flexDirection: "row",
            padding: "5px",
            mt: "8px",
            gap: "5px",
            borderRadius: "8px",
            width: "fit-content",
            justifyContent: "center",
            alignItems: "center",
            border: allUtilityBills.length && `1px solid rgba(0,0,0,.6)`,
          }}
        >
          {!!allUtilityBills.length &&
            allUtilityBills.map((image) => {
              return (
                <Grid
                  key={image.id}
                  item
                  sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    justifyContect: "center",
                    alignItems: "center",
                    "&:not(:first-of-type)": { marginLeft: 1 },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "14px", color: "rgba(0,0,0,.7)" }}
                  >
                    {image.data?.name ||
                      image?.originalname ||
                      image?.data?.originalname}
                  </Typography>
                  <Typography
                    onClick={() =>
                      handleDeleteUtilityBill(image.id, image?.url)
                    }
                    sx={{
                      fontSize: "14px",
                      color: theme.palette.warning.main,
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    Delete
                  </Typography>
                </Grid>
              );
            })}
        </Grid>

        {!allUtilityBills.length && (
          <UploadButton
            caption="Upload"
            handleUploadImage={handleUploadBillImage}
            type="utilityBill"
            styles={{
              display: "inline-block",
              margin: "0 auto",
            }}
          />
        )}
        <Grid
          container
          sx={{
            justifyContent: "flex-end",
            gap: "0.5rem",
            mt: {
              xs: "1rem",
              md: 0,
            },
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setUtilityBillFiles([]);
              closeForm();
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={submitUtilityBills}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    );
  }
);
