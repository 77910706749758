import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router";

import { Grid, Typography } from "@mui/material";

import { ReviewForm, PickDateInput, LoanCardButton } from "../../../Components";
import { updateLoan } from "../../../actions/loan";

import moment from "moment";
import { toast } from "react-toastify";
import { GrantLoanTerms } from "./components/GrantLoanTerms";
import { checkoutSubscriptionAPI } from "../../../api/payments";
import { SubscriptionModal } from "../components/SubscriptionModa";
import { LoanAgreement } from "../../../Components/LoanAgreement/LoanAgreement";
import { getRelevantData } from "../../../utils";

const Single = ({ socket }) => {
  const [grantLoanTerms, setGrantLoanTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [newPaybackDate, setNewPaybackDate] = useState(null);
  const [addressForm, setAddressForm] = useState({
    state: "",
    city: "",
    street: "",
    building: "",
    apartment: "",
    zip: "",
  });
  const [signatureValue, setSignatureValue] = useState("");
  const [openLoanAgreement, setOpenLoanAgreement] = useState(false);

  const { type } = useParams();

  const {
    auth: { user },
  } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { loan } = location.state;

  const currentCancelBtnPath =
    type === "grant" ? "/dashboard/loan-board" : "/dashboard/";

  const reviewReceiver =
    user?.id === loan.granted_by?.id ? loan.created_by : loan.granted_by;

  const getModalTitle = () => {
    if (type === "unpaid") return "a loan is not repaid?";
    if (type === "extend-date") return "extend a loan payback date?";
    if (type === "confirm-extend-date") return "confirm payback date extension";
    if (type === "cancel-extend-date") return "cancel payback date extension";
    if (type === "grant") return "Grant a loan?";
    if (type === "deny-grant") return "Deny a loan grant?";
    if (type === "confirm") return "Confirm a loan grant?";
    if (type === "repaid") return "a loan is repaid?";
  };

  const cancelTermsModal = () => {
    setGrantLoanTerms(false);
  };

  const closeLoanAgreement = () => {
    setOpenLoanAgreement(false);
  };

  const handleChangePaybackDate = (e) => {
    const { value } = e.target;

    setNewPaybackDate(value);
  };

  const changeSignatureValue = ({ target }) => {
    setSignatureValue(target.value);
  };

  const changeLoanStatus = useCallback(() => {
    const { toastMessage } = getRelevantData(type, null, null, null);
    let requestBody = {};
    if (newPaybackDate) {
      requestBody = {
        paybackDateRequest: { date: newPaybackDate, status: "pending" },
      };
    } else if (addressForm.state) {
      requestBody = {
        lenderAddress: addressForm,
      };
    }
    if (signatureValue) {
      requestBody.signature = signatureValue.trim();
    }
    dispatch(
      updateLoan(loan.id, type, requestBody, ({ data }) => {
        if (data.error) {
          setGrantLoanTerms(false);
          setIsLoading(false);
          toast.error(data.error);
        }

        if (data?.id) {
          setIsLoading(false);
          toast(toastMessage);
          if (type === "grant") {
            toast(
              "The Loan Agreement will be sent to your email address after it's signed by the Borrower."
            );
          }
          if (type === "confirm") {
            toast("The Loan Agreement has been sent to your email address.");
          }
          if (["repaid", "unpaid"].includes(type)) {
            setIsReviewFormOpen(true);
          } else {
            navigate("/dashboard");
            return;
          }
        }
      })
    );
  }, [
    loan.id,
    type,
    newPaybackDate,
    addressForm,
    dispatch,
    navigate,
    signatureValue,
  ]);

  const { currentActionBtnTitle, currentActionBtnHandler } = getRelevantData(
    type,
    changeLoanStatus,
    setGrantLoanTerms,
    setOpenLoanAgreement
  );
  const submitGrantLoan = useCallback(async () => {
    try {
      setGrantLoanTerms(null);
      dispatch({ type: "LOAD" });
      const { data } = await checkoutSubscriptionAPI("grant", loan.id);

      if (data.status === "invalid") {
        setShowSubscriptionModal(data);
        dispatch({ type: "UNLOAD" });
      } else {
        changeLoanStatus();
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  }, [changeLoanStatus, loan.id, dispatch]);

  useEffect(() => {
    if (type === "grant") {
      socket?.removeAllListeners("subscription-paid");
      socket?.on("subscription-paid", ({ message }) => {
        if (message === "OK") {
          toast("Payment was successful.");
          changeLoanStatus();
        }
      });
    }
  }, [type, changeLoanStatus, socket]);

  if (!loan) return null;
  return (
    <Grid
      container
      sx={{
        mt: "1rem",
        width: { xs: "100%", sm: "600px" },
        margin: {
          xs: 0,
          sm: "0 auto",
        },
      }}
    >
      {type === "confirm" && openLoanAgreement && (
        <LoanAgreement
          open={openLoanAgreement}
          loanID={loan?.id}
          closeModalHandler={closeLoanAgreement}
          submitCallback={changeLoanStatus}
          dispatch={dispatch}
          signatureValue={signatureValue}
          changeSignatureValue={changeSignatureValue}
          user={user}
        />
      )}
      <GrantLoanTerms
        open={!!grantLoanTerms}
        closeModalHandler={cancelTermsModal}
        submitCallback={submitGrantLoan}
        isLoading={isLoading}
        loanID={loan.id}
        lender={user}
        addressForm={addressForm}
        setAddressForm={setAddressForm}
        signatureValue={signatureValue}
        changeSignatureValue={changeSignatureValue}
        user={user}
      />
      <SubscriptionModal
        open={!!showSubscriptionModal}
        closeModalHandler={() => setShowSubscriptionModal(null)}
        subscriptionData={showSubscriptionModal}
      />
      <Grid
        container
        item
        xs={12}
        spacing={2}
        sx={{
          margin: "0 0 1em 0",
          backgroundColor: "white",
          border: "1px solid rgba(66, 103, 178,0.3)",
          boxShadow: "0px 1px 6px 0px #7C9AEA",
          borderRadius: "6px",
          padding: "1em",
        }}
      >
        <Grid item xs={12}>
          <Typography
            fontWeight={700}
            fontSize={"28px"}
            sx={{
              textTransform: "capitalize",
              textAlign: { xs: "center", sm: "start" },
            }}
          >
            {getModalTitle()}
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography style={{ fontSize: "20px" }}>Loan Amount</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent={"flex-end"}>
            <Typography style={{ fontSize: "20px" }}>
              ${loan.requestedAmount}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography style={{ fontSize: "20px" }}>
              {type.includes("extend") && "New "}Payback Date
            </Typography>
          </Grid>
          <Grid item xs={6} container justifyContent={"flex-end"}>
            {type === "extend-date" ? (
              <PickDateInput
                extendDate
                dateState={newPaybackDate || ""}
                handleChangeState={handleChangePaybackDate}
              />
            ) : (
              <Typography style={{ fontSize: "20px" }}>
                {moment(loan.paybackDateRequest?.date || loan.date).format(
                  "MM/DD/YYYY"
                )}
              </Typography>
            )}
          </Grid>
        </Grid>

        {["confirm", "deny-grant"].includes(type) && (
          <Grid
            item
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Grid item xs={6}>
              <Typography style={{ fontSize: "20px" }}>Lender</Typography>
            </Grid>
            <Grid item xs={6} container justifyContent={"flex-end"}>
              <Typography style={{ fontSize: "20px" }}>
                @{loan.granted_by.username}
              </Typography>
            </Grid>
          </Grid>
        )}
        {["grant", "repaid", "unpaid"].includes(type) && (
          <Grid
            item
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Grid item xs={6}>
              <Typography style={{ fontSize: "20px" }}>Borrower</Typography>
            </Grid>
            <Grid item xs={6} container justifyContent={"flex-end"}>
              <Typography style={{ fontSize: "20px" }}>
                @{loan.created_by.username}
              </Typography>
            </Grid>
          </Grid>
        )}

        {!isReviewFormOpen ? (
          <Grid item spacing={1} container justifyContent={"flex-end"} xs={12}>
            <Grid item>
              <LoanCardButton
                title="Cancel"
                btnProps={{
                  color: "secondary",
                  variant: "contained",
                  disabled: isLoading,
                }}
                cb={() => navigate(currentCancelBtnPath)}
                styles={{
                  textTransform: "capitalize",
                  fontSize: "16px",
                  color: "black",
                }}
              />
            </Grid>
            <Grid item>
              <LoanCardButton
                title={currentActionBtnTitle}
                btnProps={{
                  variant: "contained",
                }}
                cb={currentActionBtnHandler}
                styles={{ fontSize: "16px" }}
              />
            </Grid>
          </Grid>
        ) : (
          <ReviewForm
            user={user}
            closeReviewForm={() => setIsReviewFormOpen(false)}
            reviewReceiver={reviewReceiver}
            loanID={loan.id}
            authorRole={loan.created_by.id === user.id ? "borrower" : "lender"}
            postReviewCallback={() => {
              setGrantLoanTerms(null);
              navigate("/dashboard");
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Single;
