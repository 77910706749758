import React, { useCallback, useEffect, useState } from "react";
import { NavLink, Route, Routes, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import io from "socket.io-client";

import {
  AppBar,
  CssBaseline,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Drawer,
  Grid,
  useMediaQuery,
  Button,
  Avatar,
  Menu,
  MenuItem,
  Badge,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import { logout } from "../actions/auth";

import notification from "../assets/notification.svg";
import pie from "../assets/pie.svg";
import gear from "../assets/gear.svg";
import account from "../assets/user.svg";
import announcements from "../assets/announcements.svg";

import Home from "./Home";
import Board from "./Loan Board";
import Users from "./Users";
import ChatScreen from "../Dashboard/Messages/ChatScreen";
import Settings from "./Settings";
import { ProfilePage } from "../Pages/Profile";
import { AppSettings } from "./AppSettings";
import { AnnouncementsPage } from "./Announcements/AnnouncementsPage";
import { getAllChatsAction } from "../actions/chats";

function Dashboard() {
  const [socket, setSocket] = useState(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    auth: { user },
    chats: { chats, selectedChat },
    notifications: { notifications },
    appSettings,
  } = useSelector((state) => state);

  const small = useMediaQuery("(max-width:756px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleDrawer = () => {
    setOpen(!open);
  };
  /* Nav components */
  const components = {
    Home,
    Board,
    Users,
    ProfilePage,
    AnnouncementsPage,
    Settings,
    ChatScreen,
    AppSettings,
  };
  const currentRoutes = user.currentRoutes;
  const navLinkAssets = {
    Dashboard: { IconComponent: DashboardOutlinedIcon },
    Announcements: { img: announcements },
    Loanboard: { img: pie },
    Users: { img: account },
    Messages: { IconComponent: MessageOutlinedIcon },
    "App Settings": { IconComponent: TuneOutlinedIcon },
  };

  const handlePrefetchAdminMessages = () => {
    if (!chats) {
      dispatch(getAllChatsAction(`?userID=`));
    }
  };

  const messageListener = useCallback(
    ({ newMessage, chatID, users }) => {
      if (chatID === selectedChat?.id) {
        /*Update selected chat */
        dispatch({
          type: "UPDATE_SELECTED_CHAT",
          payload: { newMessage, chatID },
        });
      } else {
        /*Update existed chat */
        dispatch({
          type: "UPDATE_CHATS_LIST_MESSAGE",
          payload: { newMessage, chatID, users },
        });
      }
    },

    [dispatch, selectedChat?.id]
  );
  /*Handle notifications icon click */
  const handleClick = (event) => {
    notifications.length && setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menu = Boolean(anchorEl);
  /*Handle notifications item click */
  const handleNotificationClick = (id, from, type) => {
    handleClose();

    if (type === "chat") {
      const chatID = chats?.find((chat) =>
        chat.users.find((user) => user.id === from.id)
      )?.id;
      if (chatID) {
        dispatch({ type: "SET_SELECTED_CHAT", payload: { chatID } });
        navigate("/admin/messages");
      }
    } else {
      dispatch({
        type: "DELETE_NOTIFICATIONS",
        payload: {
          senderID: from.id,
          type: [type],
        },
      });

      if (window.location.href.match(/\/messages$/)) {
        return;
      } else {
        navigate("/admin/messages");
      }
    }
  };

  useEffect(() => {
    /*Socket connection */
    const newSocket = io("https://getloanr.com", {
      cookie: true,
      transports: ["polling", "websocket"],
      extraHeaders: {
        "Access-Control-Allow-Origin": "*",
      },
      reconnection: true,
    });
    newSocket.on("connect", () => {
      newSocket.emit("add user", user);
      setSocket(newSocket);
    });
    return () => newSocket.close();
  }, [user, setSocket]);

  useEffect(() => {
    /*Get in-app notifications */
    if (socket) {
      socket.emit("get_notifications", user.chatBotID);
      socket.on("set_notifications", (notifications) => {
        dispatch({
          type: "SET_INITIAL_NOTIFICATIONS",
          payload: { notifications },
        });
      });
      /*Listener for the new notifications */
      socket?.on("new-notifications", (newNotifications) => {
        dispatch({ type: "ADD_NOTIFICATIONS", payload: { newNotifications } });
      });
    }
  }, [socket, user.id, dispatch]);

  useEffect(() => {
    socket?.removeAllListeners("check-is-notification-required");

    socket?.on(
      "check-is-notification-required",
      ({ chatID, sender, receiver, type }) => {
        /*If target chat opened - in-app notification is not required */
        if (selectedChat?.id !== chatID) {
          socket?.emit("create-notification", { sender, receiver, type });
        }
      }
    );
  }, [selectedChat?.id, socket, dispatch]);

  useEffect(() => {
    socket?.removeAllListeners("new-message");
    socket?.on("new-message", messageListener);
  }, [socket, messageListener, dispatch]);

  useEffect(() => {
    handlePrefetchAdminMessages();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        open={open}
        sx={{ backgroundColor: "#e7efff", boxShadow: "none", zIndex: 1 }}
      >
        <Toolbar>
          <Grid item container alignItems={"center"}>
            {" "}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon style={{ color: "black", fontSize: "1.1em" }} />
            </IconButton>
          </Grid>

          <Box
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              padding: small ? "0" : "0 2em",
              justifyContent: "flex-end",
            }}
          >
            {/* Notifications icon */}
            <IconButton
              onClick={handleClick}
              color="inherit"
              style={{ marginRight: "1em" }}
            >
              <Badge badgeContent={notifications?.length} color="primary">
                <img src={notification} alt="notif" />
              </Badge>
            </IconButton>
            {/* Notifications list */}
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={menu}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: "fit-content",
                  maxHeight: "300px",
                },
              }}
            >
              {notifications?.map(({ id, from, type }) => {
                if (id && from && type) {
                  return (
                    <MenuItem
                      onClick={() => handleNotificationClick(id, from, type)}
                      key={id}
                      sx={{ padding: "1em", width: "100%" }}
                    >
                      <Avatar src={""} sx={{ marginRight: "0.4em" }}>
                        {from.username[0]}
                      </Avatar>
                      {type === "chat" && `${from.username} sent you a message`}
                    </MenuItem>
                  );
                }
              })}
            </Menu>
            <IconButton component={Link} to="/admin/settings" color="inherit">
              <Avatar>{user.username[0]}</Avatar>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        onClose={toggleDrawer}
        open={open}
        sx={{
          zIndex: 1,
          width: small ? "40vw" : "20vw",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: small ? "65vw" : "20vw",
            boxSizing: "border-box",
          },
        }}
        variant={!small ? "permanent" : "temporary"}
        anchor="left"
      >
        <Box sx={{ px: 2, py: 1 }}>
          <img
            src="/logo.jpg"
            style={{ width: small ? "30vw" : "10vw" }}
            alt="logo"
          />
        </Box>
        <Toolbar />
        <Box>
          {currentRoutes.length && (
            <>
              {currentRoutes.map((route) => {
                if (route.menuItem.to) {
                  return (
                    <DrawerButton
                      key={route.menuItem.title}
                      toggleDrawer={toggleDrawer}
                      to={route.menuItem.to}
                      title={route.menuItem.title}
                      {...navLinkAssets[route.menuItem.title]}
                    />
                  );
                }
                return null;
              })}
            </>
          )}
          {/* STATIC BUTTONS */}
          {/* Settings */}
          <DrawerButton
            toggleDrawer={toggleDrawer}
            to="/admin/settings"
            title="Settings"
            img={gear}
          />
        </Box>
        {/* Logout */}
        <Box
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              dispatch(logout(navigate, toggleDrawer));
            }}
            style={{
              color: "black",
              padding: "0.5em",
              marginBottom: "1em",
              textTransform: "capitalize",
            }}
          >
            <Grid item container xs={12} alignItems="center">
              <Grid
                item
                xs={3}
                container
                justifyContent={"center"}
                alignItems="center"
              >
                <LogoutOutlinedIcon />
              </Grid>
              <Grid container item xs={7}>
                <Typography fontSize={24}>Logout</Typography>
              </Grid>
            </Grid>
          </Button>
        </Box>
      </Drawer>
      <Box
        component="main"
        style={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          backgroundColor: "#e7efff",
          padding: small ? "none" : "0 0.5em",
        }}
      >
        <Toolbar />
        {currentRoutes && (
          <Routes>
            {currentRoutes.map(({ path, componentName }) => {
              const Component = components[componentName];
              return (
                <Route
                  key={componentName}
                  path={path}
                  element={<Component socket={socket} />}
                />
              );
            })}

            <Route path="settings/*" element={<Settings />} />
          </Routes>
        )}
      </Box>
    </Box>
  );
}

export default Dashboard;

const DrawerButton = ({ title, to, img, IconComponent, toggleDrawer }) => {
  return (
    <Button
      fullWidth
      style={{
        color: "black",
        padding: 0,
        marginBottom: "1em",
        textTransform: "capitalize",
      }}
    >
      <NavLink
        onClick={toggleDrawer}
        to={to}
        style={({ isActive }) =>
          isActive
            ? {
                backgroundColor: "rgba(124, 154, 234,0.7)",
                color: "black",
                textDecoration: "none",
                height: "100%",
                width: "100%",
                padding: "0.5em",
              }
            : {
                color: "black",
                textDecoration: "none",
                height: "100%",
                width: "100%",
                padding: "0.5em",
              }
        }
      >
        <Grid item container xs={12}>
          <Grid
            item
            xs={3}
            container
            justifyContent={"center"}
            alignItems="center"
          >
            {img && <img width="25px" src={img} alt={`${title} +icon`} />}
            {IconComponent && <IconComponent />}
          </Grid>
          <Grid item xs={8} container>
            <Typography fontSize={24}>{title}</Typography>
          </Grid>
        </Grid>
      </NavLink>
    </Button>
  );
};
