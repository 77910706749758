import { IconButton, Input, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const UploadButton = ({
  handleUploadImage,
  type = "collateralImages",
  caption,
  styles,
}) => {
  const theme = useTheme();
  return (
    <label htmlFor={`${type}-upload-image`}>
      <Input
        style={{ display: "none" }}
        id={`${type}-upload-image`}
        onChange={(e) => handleUploadImage(e, type)}
        name={`${type}-upload-image`}
        type="file"
        inputProps={{ multiple: true, accept: "image/png, image/jpeg" }}
      />
      <IconButton
        size="small"
        component="span"
        aria-label="add"
        variant="extended"
      >
        <Typography
          variant="contained"
          color="primary"
          sx={{
            fontSize: "1rem",
            padding: "0.5rem 1rem",
            border: `1px solid ${theme.palette.primary.main} `,
            ...styles,
          }}
        >
          {caption}
        </Typography>
      </IconButton>
    </label>
  );
};
