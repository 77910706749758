import axios from "axios";
import baseurl from "./url";
const API = axios.create({ baseURL: `${baseurl}/admin` });
API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = localStorage.getItem("token");
  }
  return req;
});

export const getusers = (query) => API.get(`/users?${query}`);
export const suspendusers = (ids) => API.post("/suspend", ids);
export const deleteusers = (ids) => API.post("/delete", ids);
export const dashboardcounts = () => API.get("/dashboard-counts");
export const getUsersZipcodesDataAPI = () =>
  API.get("/dashboard-counts?type=zipcodes");
export const dashboard = (query) => API.get(`/dashboard?${query}`);

/*Announcements */
export const getAnnouncementsAPI = () => API.get(`/announcements`);
export const createAnnouncementAPI = (announcement) =>
  API.post(`/announcements`, announcement);
export const updateAnnouncementAPI = (id, newData) =>
  API.patch(`/announcements/${id}`, newData);
export const deleteAnnouncementAPI = (id) => API.delete(`/announcements/${id}`);

/*Grant user access */
export const grantUserAccessAPI = (id, newData) =>
  API.patch(`/access/${id}`, newData);

/*Loan request */

export const updateLoanAPI = (id, type, body) =>
  API.put(`/loans/${id}?type=${type}`, body);

//   App Settings
export const updateAppSettingsAPI = (body) => API.put(`/app-settings`, body);
