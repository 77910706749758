import React, { useEffect, useState } from "react";

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { LoanCardButton } from "../LoanCardButton";
import { getAgreementDataAPI } from "../../api/loan";

import { toast } from "react-toastify";

export const LoanAgreement = ({
  open,
  loanID,
  closeModalHandler,
  submitCallback,
  isLoading,
  dispatch,
  lenderData,
  signatureValue,
  changeSignatureValue,
  addressForm,
  user,
}) => {
  const [agreementData, setAgreementData] = useState(null);
  const [checked, setChecked] = useState(false);
  const [signatureError, setSignatureError] = useState(false);

  const { agreementSides, paragraphs, signatures, agreementDate } =
    agreementData || {};

  const actionBtnClick = () => {
    const trimmedValue = signatureValue.trim();
    if (checked && trimmedValue && trimmedValue === user.name) {
      submitCallback();
    } else if (!checked) {
      toast.error("Please accept the checkbox");
    } else {
      toast.error("Please type your full name as signature");
      setSignatureError(true);
    }
  };

  useEffect(() => {
    (async () => {
      dispatch({ type: "LOAD" });
      const { data } = await getAgreementDataAPI(loanID, addressForm);
      if (data) {
        setAgreementData(data);
      }
      dispatch({ type: "UNLOAD" });
    })();
  }, []);

  return (
    <Modal sx={{ zIndex: 1300 }} open={open}>
      <Paper
        className="fade-in-slow"
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          padding: "1em 1.5em",
          borderRadius: "8px",
          maxHeight: "100%",
          height: "95%",
          width: {
            xs: "90%",
            sm: "75%",
            md: "450px",
          },
          overflowY: "auto",
        }}
      >
        {agreementData && (
          <Grid>
            <Typography
              variant="h5"
              fontSize={20}
              sx={{ fontWeight: "bold", textAlign: "center" }}
              dangerouslySetInnerHTML={{ __html: agreementData.title }}
            ></Typography>
            <Grid container>
              <Typography
                fontSize={"14px"}
                sx={{ mt: 2 }}
                dangerouslySetInnerHTML={{ __html: agreementData.header }}
              ></Typography>
              <Grid
                container
                sx={{
                  flexDirection: "column",
                }}
              >
                {["lender", "borrower"].map((agreementSide) => {
                  return (
                    <Grid key={agreementSide}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          textTransform: "capitalize",
                        }}
                      >
                        {agreementSide}:
                      </Typography>
                      {["name", "address", "phone", "email"].map((item) => {
                        return (
                          <Typography
                            fontSize={14}
                            key={`${agreementSide}_${item}`}
                          >
                            <span style={{ textTransform: "capitalize" }}>
                              {item}
                            </span>
                            : {agreementSides?.[agreementSide]?.[item]}
                          </Typography>
                        );
                      })}
                    </Grid>
                  );
                })}
              </Grid>
              {/* Paragraphs */}
              <Grid container sx={{ flexDirection: "column" }}>
                {paragraphs?.map(({ id, title, description }) => {
                  return (
                    <Grid key={"paragraph-id-" + id}>
                      <Typography
                        fontSize={"15px"}
                        sx={{ mt: "5px", fontWeight: "bold" }}
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></Typography>
                      <Typography
                        fontSize={"14px"}
                        dangerouslySetInnerHTML={{ __html: description }}
                      ></Typography>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                container
                sx={{
                  flexDirection: "column",
                  mt: 1,
                }}
              >
                {["lender", "borrower"].map((agreementSide) => {
                  const isForLender = agreementSide === "lender";
                  return (
                    <Grid key={agreementSide}>
                      <Typography
                        fontSize={15}
                        sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                      >
                        {agreementSide}:
                      </Typography>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography fontSize={14}>
                          Signature: {isForLender && lenderData && null}
                          {isForLender && signatures.lender}
                          {!isForLender && lenderData && "___________"}
                        </Typography>
                        {((isForLender && lenderData) ||
                          (!isForLender && signatures.lender)) && (
                          <TextField
                            required
                            size="small"
                            error={signatureError}
                            helperText={
                              signatureError && "Please type your full name"
                            }
                            sx={{
                              margin: "3px 0",
                            }}
                            fullWidth
                            name="signature"
                            placeholder="Full name"
                            value={signatureValue}
                            onChange={(e) => {
                              setSignatureError(false);
                              changeSignatureValue(e);
                            }}
                          />
                        )}
                      </Grid>

                      <Typography fontSize={14}>
                        Name: {agreementSides?.[agreementSide]?.name}
                      </Typography>
                      <Typography fontSize={14}>
                        Date: {agreementDate}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid>
                <FormControlLabel
                  sx={{ mt: 1 }}
                  label={
                    <Typography color="#727987" fontSize={"14px"}>
                      I understand that I am signing this legally binding
                      document electronically
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      sx={{ color: "#DDDDDD", borderRadius: "1px" }}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                height: "fit-content",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
                mt: 1,
              }}
            >
              <LoanCardButton
                title="Cancel"
                btnProps={{
                  color: "secondary",
                  variant: "contained",
                  disabled: isLoading,
                }}
                cb={closeModalHandler}
                styles={{
                  mr: 1,
                  mt: { xs: 1, sm: 0 },
                  fontSize: "16px",
                }}
              />

              <LoanCardButton
                title={lenderData ? "Grant Loan" : "Confirm"}
                btnProps={{
                  variant: "contained",
                  disabled: isLoading,
                }}
                cb={actionBtnClick}
                styles={{
                  mr: 1,
                  fontSize: "16px",
                }}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </Modal>
  );
};
