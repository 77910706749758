import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";

import { createLoans } from "../../../actions/loan";
import { LoanboardRequestModal } from "./LoanboardRequestModal";
import { LoanRequestForm } from "../components/LoanRequestForm";
import { StripePayments } from "../../../Components/Payments/Stripe/StripePyaments";

import { toast } from "react-toastify";
import { getAppSettingsAction } from "../../../actions/services";

const Request = ({ socket }) => {
  const {
    appSettings: { enableCollateral, enableLoanRequestReview },
  } = useSelector((state) => state);

  const getInitialCollateralState = useCallback(() => {
    let res = { collateral: {} };
    if (enableCollateral) {
      res.collateral = {
        title: "",
        description: "",
      };
      res.collateralFiles = [];
    } else {
      res.paystubFiles = [];
    }
    return res;
  }, [enableCollateral]);

  const getInitialState = useCallback(() => {
    let state = {
      requestedAmount: "",
      paybackAmount: "",
      date: "",
      borrowerLocation: "",
      isLoanEditing: false,
      isCollateralExist: false,
      utilityBillFiles: [],
    };

    Object.assign(state, getInitialCollateralState());

    return state;
  }, [getInitialCollateralState]);

  const [requestState, setRequestState] = useState(getInitialState());

  const [addressForm, setAddressForm] = useState({
    state: "",
    city: "",
    street: "",
    building: "",
    apartment: "",
    zip: "",
  });

  const [showRequestTerms, setShowRequestTerms] = useState(false);

  const [isPaymentRequired, setIsPaymentRequired] = useState(false);
  const [revise, setRevise] = useState(false);
  const [publishData, setPublishData] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getRequestFormData = useCallback(
    (status) => {
      const formData = new FormData();
      formData.append("status", status);

      for (let key in requestState) {
        if (key === "collateral" && requestState[key]) {
          formData.append(key, JSON.stringify(requestState[key]));
        } else if (key === "collateralFiles" && requestState[key].length) {
          for (let image of requestState[key]) {
            formData.append(key, image.data);
          }
        } else if (key === "paystubFiles" && requestState[key].length) {
          for (let image of requestState[key]) {
            formData.append(key, image.data);
          }
        } else if (key === "utilityBillFiles" && requestState[key].length) {
          for (let image of requestState[key]) {
            formData.append(key, image.data);
          }
        } else {
          formData.append(key, requestState[key]);
        }
      }

      return formData;
    },
    [requestState]
  );

  const handleLoanResponse = useCallback(
    (toastMessage, response) => {
      if (!response.isPublishAllowed || response.isSubscriptionPaused) {
        setPublishData(response);
      }

      if (["pending", "saved", "published"].includes(response?.status)) {
        /*Processing "pending,saved,published" loans*/
        setShowRequestTerms(false);
        setRequestState(getInitialState());
        setIsPaymentRequired(false);
        navigate("/dashboard");
        toast(toastMessage);
      } else if (response.status === "invalid" && response.price) {
        /*Processing required subscription*/
        setPublishData(response);
        setIsPaymentRequired(true);
      } else if (
        response?.status === "valid" &&
        response?.action === "surcharge"
      ) {
        /*Processing subscription upgrade*/
        setRevise(response);
        setIsPaymentRequired(true);
      }
      dispatch({ type: "UNLOAD" });
    },
    [dispatch, getInitialState, navigate]
  );

  const submitLoanRequest = useCallback(
    async (loanStatus) => {
      const toastMessage =
        loanStatus === "saved"
          ? "Loan request has been saved to the dashboard"
          : loanStatus === "pending"
          ? "Loan request has been sent for review"
          : "Loan request has been published";

      const formData = getRequestFormData(loanStatus);
      formData.append("address", JSON.stringify(addressForm));

      dispatch(
        createLoans(formData, (response) => {
          handleLoanResponse(toastMessage, response);
        })
      );
    },
    [requestState, addressForm]
  );

  const handleCloseTermsModal = useCallback(() => {
    setIsPaymentRequired(false);
    setShowRequestTerms(false);
    setRevise(false);
    setPublishData(null);
  }, [setShowRequestTerms]);

  useEffect(() => {
    socket?.removeAllListeners("subscription-paid");
    socket?.on("subscription-paid", ({ message }) => {
      if (message === "OK") {
        toast("Payment was successful.");
        submitLoanRequest("published");
      }
    });
  }, [submitLoanRequest]);

  useEffect(() => {
    dispatch(getAppSettingsAction());
  }, []);

  useEffect(() => {
    const initialState = getInitialState();
    setRequestState(initialState);
  }, [getInitialState]);

  return (
    <Grid container justifyContent="center">
      {showRequestTerms && (
        <LoanboardRequestModal
          open={showRequestTerms}
          revise={revise}
          closeModalHandler={handleCloseTermsModal}
          submitCallback={submitLoanRequest}
          PaymentComponent={isPaymentRequired ? StripePayments : null}
          publishData={publishData}
          requestedAmount={requestState.requestedAmount}
          addressForm={addressForm}
          setAddressForm={setAddressForm}
          enableLoanRequestReview={enableLoanRequestReview}
        />
      )}

      <LoanRequestForm
        requestState={requestState}
        cancelClickHandler={() => navigate("/dashboard/loan-board")}
        submitButtonTitle="Next"
        setRequestState={setRequestState}
        submitCallback={() => setShowRequestTerms(true)}
        enableCollateral={enableCollateral}
        getInitialCollateralState={getInitialCollateralState}
      />
    </Grid>
  );
};

export default Request;
