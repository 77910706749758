import React from "react";

import { Button, Grid, Modal, Paper } from "@mui/material";

export const RefundModal = ({ isOpen, handleCloseModel, handleRefund }) => {
  return (
    <Modal open={isOpen}>
      <Paper
        className="fade-in-slow"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          padding: "1.5em 2em",
          borderRadius: "1em",
          textAlign: "center",
          width: { xs: "90vw", md: "30vw" },
        }}
      >
        <Grid
          sx={{ mt: 3 }}
          style={{ textAlign: "center", fontSize: "1.4em", fontWeight: 500 }}
          item
          xs={12}
        >
          Do you want to continue refund?
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", gap: "8px", margin: "0 auto" }}
        >
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleCloseModel}
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "1em",
              borderRadius: "5px",
              textTransform: "none",
            }}
          >
            No
          </Button>
          <Button
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleRefund}
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "1em",
              borderRadius: "5px",
              textTransform: "none",
            }}
          >
            Yes
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
};
