import React, { useCallback, useMemo, useState } from "react";

import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { PickDateInput } from "../../../Components";
import { formValidator } from "../../../utils";
import {
  LoanRequestFormInput,
  LoanRequestInputWrapper,
} from "./LoanRequestFormItems";

import {
  UtilityBillsForm,
  PaystubsUploadForm,
  CollateralForm,
} from "./Collateral";

export function LoanRequestForm({
  requestState,
  setRequestState,
  formTitle = "Loan Request",
  cancelClickHandler,
  submitButtonTitle,
  submitCallback,
  enableCollateral,
  getInitialCollateralState,
}) {
  const [showCollateralForm, setShowCollateralForm] = useState(false);
  const [showUtilityBillsForm, setShowUtilityBillsForm] = useState(false);
  const [showPaystubsForm, setShowPaystubsForm] = useState(false);
  const [errors, setErrors] = useState({
    date: false,
    requestedAmount: false,
    paybackAmount: false,
    borrowerLocation: false,
    isCollateralExist: false,
    utilityBills: false,
  });
  const theme = useTheme();

  const {
    isCollateralExist,
    date,
    borrowerLocation,
    isLoanEditing,
    requestedAmount,
  } = requestState || {};

  const isUtilityBillsExists = useMemo(
    () =>
      Boolean(
        (requestState?.utilityBills || []).length +
          (requestState?.utilityBillFiles || []).length
      ),
    [requestState]
  );

  const handleAddCollateralClick = () => setShowCollateralForm(true);

  const handleDeleteCollateralClick = () => {
    setRequestState((prevState) => ({
      ...prevState,
      ...getInitialCollateralState(),
      isCollateralExist: false,
    }));
  };

  const handleInputChange = useCallback(
    (e) => {
      const { id, value } = e.target;

      setRequestState((prevState) => ({ ...prevState, [id]: value }));
      setErrors((prevState) => ({ ...prevState, [id]: false }));
    },
    [setRequestState]
  );

  const handleFormValidation = (e) => {
    e.preventDefault();

    const [isValid, rejectedInput] = formValidator(requestState, "loan");

    if (isValid) {
      submitCallback();
    } else {
      setErrors((state) => ({ ...state, [rejectedInput]: true }));
    }
  };

  if (!requestState) return null;

  /* Render Collateral/Paystubs Form */
  if (enableCollateral && showCollateralForm) {
    return (
      <CollateralForm
        requestState={requestState}
        setRequestState={setRequestState}
        warningColor={theme.palette.warning.main}
        closeForm={() => setShowCollateralForm(false)}
        setErrors={setErrors}
        theme={theme}
      />
    );
  }

  /* Render Paystubs form */
  if (showPaystubsForm) {
    return (
      <PaystubsUploadForm
        requestState={requestState}
        setRequestState={setRequestState}
        setErrors={setErrors}
        closeForm={() => setShowPaystubsForm(false)}
        theme={theme}
      />
    );
  }

  /* Render Utility Bills Form */
  if (showUtilityBillsForm) {
    return (
      <UtilityBillsForm
        requestState={requestState}
        setRequestState={setRequestState}
        closeForm={() => setShowUtilityBillsForm(false)}
        setErrors={() => setShowPaystubsForm(false)}
        theme={theme}
      />
    );
  }

  return (
    <Grid
      container
      component="form"
      noValidate
      onSubmit={handleFormValidation}
      item
      xs={11}
      justifyContent={"flex-start"}
      style={{
        marginBottom: "1rem",
        backgroundColor: "white",
        border: "1px solid rgba(66, 103, 178,0.3)",
        boxShadow: "0px 1px 6px 0px #7C9AEA",
        borderRadius: "6px",
        padding: "1rem",
        gap: "8px",
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{ fontWeight: 800, fontSize: "25px" }}
      >
        {formTitle}
      </Typography>

      {/* Requested Amount */}
      <LoanRequestInputWrapper title="Requested Amount">
        <LoanRequestFormInput
          fullWidth
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={requestedAmount}
          placeholder="100"
          id="requestedAmount"
          error={errors.requestedAmount}
          helperText={
            errors.requestedAmount
              ? "Please enter amount. Request must be less than $1,000"
              : ""
          }
          onChange={(e) => {
            if (!isNaN(e.target.value)) handleInputChange(e);
          }}
          name="requestedAmount"
          autoComplete="requestedAmount"
        />
      </LoanRequestInputWrapper>

      {/* Payback Amount */}
      <LoanRequestInputWrapper title="Payback Amount">
        <LoanRequestFormInput
          fullWidth
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={requestState.paybackAmount}
          placeholder="Tip to lender suggested"
          id="paybackAmount"
          error={errors.paybackAmount}
          helperText={
            errors.paybackAmount
              ? `Please enter amount, allowed range $${requestedAmount}-$${(
                  +requestedAmount +
                  +requestedAmount * 0.15
                ).toFixed(0)}`
              : ""
          }
          onChange={(e) => {
            if (!isNaN(e.target.value)) handleInputChange(e);
          }}
          name="paybackAmount"
          autoComplete="paybackAmount"
        />
      </LoanRequestInputWrapper>

      {/* Payback Date */}
      <LoanRequestInputWrapper title="Payback Date">
        <PickDateInput
          dateState={date ? new Date(date).toISOString()?.split("T")[0] : ""}
          error={errors.date}
          errorText={
            errors.date
              ? `Please enter date greater then ${new Date().toLocaleDateString()}`
              : ""
          }
          handleChangeState={handleInputChange}
        />
      </LoanRequestInputWrapper>

      {/* Location */}
      <LoanRequestInputWrapper title="Location">
        <LoanRequestFormInput
          fullWidth
          required
          inputProps={{ maxLength: 20 }}
          value={borrowerLocation}
          placeholder="Where are you located?"
          id="borrowerLocation"
          error={errors.borrowerLocation}
          helperText={
            errors.borrowerLocation
              ? "Please enter your location min.length-3 chars"
              : ""
          }
          onChange={handleInputChange}
          name="borrowerLocation"
          autoComplete="borrowerLocation"
        />
      </LoanRequestInputWrapper>

      {/* Collateral Form If Enabled*/}
      {enableCollateral && (
        <LoanRequestInputWrapper title="Collateral" id="collateral">
          <Grid
            container
            sx={{
              alignItems: "center",
              gap: 1,
            }}
          >
            <Grid sx={{ width: "fit-content" }}>
              <Button
                variant={!isCollateralExist ? "outlined" : "contained"}
                color="primary"
                onClick={handleAddCollateralClick}
                sx={{ fontSize: "0.8rem", mt: "8px" }}
              >
                {isCollateralExist ? "Edit Collateral" : "Add Collateral"}
              </Button>

              {!isLoanEditing && isCollateralExist && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleDeleteCollateralClick}
                  sx={{ ml: 1, fontSize: "0.8rem", mt: 1 }}
                >
                  Delete
                </Button>
              )}
              {errors.isCollateralExist && (
                <FormHelperText
                  sx={{ ml: 1, color: "#d32f2f" }}
                  error={errors.collateral}
                >
                  Please add collateral
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </LoanRequestInputWrapper>
      )}

      {/* Paystubs Upload If Enabled*/}
      {!enableCollateral && (
        <LoanRequestInputWrapper title="Paystubs">
          <Grid sx={{ width: "fit-content" }}>
            <Button
              variant={!isCollateralExist ? "outlined" : "contained"}
              color="primary"
              onClick={() => setShowPaystubsForm(true)}
              sx={{ fontSize: "0.8rem", mt: "8px" }}
            >
              {isCollateralExist ? "Edit Paystubs" : "Add Paystubs"}
            </Button>

            {!isLoanEditing && isCollateralExist && (
              <Button
                variant="contained"
                color="warning"
                onClick={handleDeleteCollateralClick}
                sx={{ ml: 1, fontSize: "0.8rem", mt: 1 }}
              >
                Delete
              </Button>
            )}
            {errors.isCollateralExist && (
              <FormHelperText
                sx={{ ml: 1, color: "#d32f2f" }}
                error={errors.collateral}
              >
                Please add paystubs
              </FormHelperText>
            )}
          </Grid>
        </LoanRequestInputWrapper>
      )}

      {/* Utilty Bills */}
      <LoanRequestInputWrapper title="Utilty Bill">
        <Grid sx={{ width: "fit-content" }}>
          <Button
            variant={!isUtilityBillsExists ? "outlined" : "contained"}
            color="primary"
            onClick={() => setShowUtilityBillsForm(true)}
            sx={{ fontSize: "0.8rem", mt: "8px" }}
          >
            {!!!isUtilityBillsExists ? "Add Utility Bill" : "Edit Utility Bill"}
          </Button>

          {!isLoanEditing && isUtilityBillsExists && (
            <Button
              variant="contained"
              color="warning"
              onClick={() =>
                setRequestState((prevState) => ({
                  ...prevState,
                  utilityBillFiles: [],
                }))
              }
              sx={{ ml: 1, fontSize: "0.8rem", mt: 1 }}
            >
              Delete
            </Button>
          )}
          {errors.utilityBillFiles && (
            <FormHelperText
              sx={{ ml: 1, color: "#d32f2f" }}
              error={errors.utilityBillFiles}
            >
              Please add utility bill
            </FormHelperText>
          )}
        </Grid>
      </LoanRequestInputWrapper>

      {/*Form Action Buttons */}
      <Grid
        item
        spacing={1}
        sx={{ mt: 1 }}
        container
        justifyContent={"flex-end"}
        xs={12}
      >
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={cancelClickHandler}
            style={{ textTransform: "capitalize", fontSize: "1rem" }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            style={{
              textTransform: "none",
              fontSize: "1rem",
            }}
          >
            {submitButtonTitle}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
