import React from "react";

import { Avatar, Menu, MenuItem } from "@mui/material";

import warning from "../../assets/warning.svg";

export const NotificationsList = ({
  notifications,
  handleNotificationClick,
  anchorEl,
  handleClose,
  menu,
}) => {
  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      anchorEl={anchorEl}
      open={menu}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "fit-content",
          maxHeight: "300px",
        },
      }}
    >
      {notifications?.map(({ id, from, type }) => {
        if (id && from && type) {
          return (
            <MenuItem
              onClick={() => handleNotificationClick(id, from, type)}
              key={id}
              sx={{ padding: "1em", width: "100%" }}
            >
              <Avatar
                src={type === "system-chat" ? warning : ""}
                sx={{ marginRight: "0.4em" }}
              >
                {from.username[0]}
              </Avatar>
              {type === "chat" && `${from.username} sent you a message`}
              {type === "grant" && `${from.username} granted you a loan`}
              {type === "confirm" && `${from.username} confirmed loan grant`}
              {type === "repaid" && `loan from ${from.username} was repaid`}
              {type === "unpaid" && `loan from ${from.username} was not repaid`}
              {type === "deny-grant" &&
                `${from.username} denied your loan grant`}
              {type === "extend-date" &&
                `${from.username} requested new payback date`}
              {type === "confirm-extend-date" &&
                `${from.username} confirmed new payback date`}
              {type === "cancel-extend-date" &&
                `${from.username} cancel new payback date`}
              {type === "system-chat" && `you have a new system message`}
              {type === "refunded" && `Your subscription payment was refunded`}
              {type === "declined" && `Your loan request was declined`}
              {type === "accepted" && `Your loan request was accepted`}
            </MenuItem>
          );
        }
      })}
    </Menu>
  );
};
