import { Grid, TextField, Typography, styled } from "@mui/material";

export const LoanRequestFormInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgba(255,255,255,0.6)",
    height: "2.8rem",
    borderRadius: "10px",
    marginTop: "7px",
  },
});

export const LoanRequestInputWrapper = ({ children, title }) => {
  return (
    <Grid item xs={12}>
      <Typography
        component="h1"
        variant="h5"
        sx={{ fontWeight: 500, fontSize: "20px" }}
      >
        {title}
        <span style={{ color: "#d32f2f" }}>*</span>
      </Typography>
      {children}
    </Grid>
  );
};
