import React from "react";

import {
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

export const ReviewModal = ({
  isOpen,
  closeReviewModal,
  handleRequestReview,
  requestReviewComment,
  setRequestReviewComment,
}) => {
  return (
    <Modal open={isOpen}>
      <Paper
        className="fade-in-slow"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          textAlign: "center",
          width: { xs: "90vw", sm: "500px" },
        }}
      >
        <Grid
          container
          sx={{
            mt: 1,
            justifyContent: "center",
            fontWeight: 500,
            width: "100%",
            p: 1,
          }}
        >
          <Typography variant="h5">Review</Typography>
          <TextField
            label="Comment"
            multiline
            rows={4}
            placeholder="Optional"
            sx={{ width: "100%", mt: 1 }}
            value={requestReviewComment}
            onChange={({ target }) => setRequestReviewComment(target.value)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", gap: "8px", margin: "0 auto" }}
        >
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleRequestReview("accepted")}
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "1em",
              borderRadius: "5px",
              textTransform: "none",
            }}
          >
            Accept
          </Button>
          <Button
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleRequestReview("declined")}
            color="warning"
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "1em",
              borderRadius: "5px",
              textTransform: "none",
            }}
          >
            Decline
          </Button>
          <Button
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={closeReviewModal}
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "1em",
              borderRadius: "5px",
              textTransform: "none",
            }}
          >
            Close
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
};
