import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const RequestReviewComment = ({ requestReviewComment }) => {
  return (
    <Accordion sx={{ mt: 1 }}>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        <Typography component="span">Review Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{requestReviewComment}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
