import { getAppSettingsAPI } from "../api/services";

export const getAppSettingsAction = (cb) => async (dispatch) => {
  try {
    const { data } = await getAppSettingsAPI();
    const { appSettings } = data;

    if (appSettings) {
      dispatch({ type: "SET_APP_SETTINGS", appSettings });
      if (typeof cb === "function") {
        cb(data);
      }
    }

    dispatch({ type: "UNLOAD" });
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    return error;
  }
};
