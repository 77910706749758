import React, { useState, useEffect, useCallback } from "react";
import { NavLink, Route, Routes, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  AppBar,
  CssBaseline,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Drawer,
  Grid,
  useMediaQuery,
  Button,
  Avatar,
  Badge,
  Alert,
  TextField,
  FormHelperText,
  Backdrop,
} from "@mui/material";
import {
  Menu as MenuIcon,
  LogoutOutlined as LogoutOutlinedIcon,
  DashboardOutlined as DashboardOutlinedIcon,
  MessageOutlined as MessageOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  ReportProblemOutlined as ReportProblemOutlinedIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import Home from "./Home";
import Board from "./LoanBoard";
import Settings from "./Settings";
import ChatScreen from "./Messages/ChatScreen";
import { ProfilePage } from "../Pages/Profile";
import DeleteLoan from "../Components/DeleteLoan";

import { logout } from "../actions/auth";
import { deleteLoan, getLoans } from "../actions/loan";

import notif from "../assets/notification.svg";
import pie from "../assets/pie.svg";
import gear from "../assets/gear.svg";

import io from "socket.io-client";

import { toast } from "react-toastify";
import { TipsModal } from "../Components";
import { ClipLoader } from "react-spinners";
import { ReportForm } from "../Components/ReportForm";
import { sendReport } from "../api/services";
import { getAllChatsAction } from "../actions/chats";
import { NotificationsList } from "./components/NotificationsList";

const verificationSMSInitialState = {
  status: null,
  sid: null,
};

function Dashboard() {
  const [socket, setSocket] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [deletedLoanId, setDeletedLoanId] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isReportFormOpen, setIsReportFormOpen] = useState(false);
  const [reportFormData, setReportFormData] = useState({
    title: "",
    message: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [verificationSMS, setVerificationSMS] = useState(
    verificationSMSInitialState
  );
  const [openVirificationDialog, setOpenVerificationDialog] = useState(false);
  const [openTipsModal, setOpenTipsModal] = useState(false);
  const [isLoansLoading, setIsLoansLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    auth: { user },
    appSettings,
    chats: { selectedChat, chats },
    notifications: { notifications },
  } = useSelector((state) => state);
  const small = useMediaQuery("(max-width:756px)");
  const mid = useMediaQuery("(max-width:1100px)");

  const menu = Boolean(anchorEl);

  const handleClick = (event) => {
    notifications.length && setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDashboardData = useCallback(
    (filter) => {
      let url = `userID=${user.id}&type=dashboard`;
      if (filter) url += `&${filter}`;
      setIsLoansLoading(true);
      dispatch({ type: "RESET_LOANS" });
      dispatch(getLoans(url, () => setIsLoansLoading(false)));
    },
    [dispatch, user.id]
  );

  /* <========
  <lamar>
  We can handle unseen notifications counter and update the badge */
  const handleNotificationClick = (id, from, type) => {
    handleClose();

    if (type === "chat" || type === "system-chat") {
      const chatID = chats?.find((chat) =>
        chat.users.find((user) => user.id === from.id)
      )?.id;
      if (chatID) {
        dispatch({ type: "SET_SELECTED_CHAT", payload: { chatID } });
        navigate("/dashboard/messages");
      }
    } else {
      dispatch({
        type: "DELETE_NOTIFICATIONS",
        payload: {
          senderID: from.id,
          type: [type],
        },
      });

      if (window.location.href.match(/\/dashboard$/)) {
        getDashboardData();
      } else {
        navigate("/dashboard");
      }
    }
  };
  /*=========> */

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleSendRequestSMSCode = () => {
    socket.emit("verification-sms-request", user.id);
  };

  const handleVerifySmsCode = (smsCode) => {
    socket.emit("verify-sms-code", {
      smsCode,
      userID: user.id,
      messageSid: verificationSMS.sid,
    });
  };
  const handleResetVerificationSMS = () => {
    setVerificationSMS(verificationSMSInitialState);
  };

  const handleInfoBtnClick = () => {
    setOpenTipsModal(!openTipsModal);
    if (user.isTipsShown === undefined) {
      dispatch({
        type: "UPDATE PROFILE",
        data: {
          ...user,
          isTipsShown: true,
        },
      });
    }
  };

  const deleteLoanBtnClick = (loanId) => {
    setDeletedLoanId(loanId);
    setIsOpenDeleteModal(true);
  };

  const deleteLoanHandler = () => {
    dispatch(deleteLoan(deletedLoanId, `userID=${user.id}&type=dashboard`));
  };

  const handleReportFormChange = (e) => {
    setReportFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleCloseReportForm = () => {
    setReportFormData({ title: "", message: "" });
    setIsReportFormOpen(false);
  };

  const handleSubmitReport = async () => {
    if (reportFormData.title && reportFormData.message) {
      const { data } = await sendReport(reportFormData);

      if (data.isReportSend) {
        toast("Report was sent successfully");
      } else {
        toast("Sometheng went wrong. Try again later");
      }
      handleCloseReportForm();
    } else {
      toast.error("Title or Message can't be empty");
    }
  };

  const handlePrefetchChats = () => {
    if (!chats) {
      dispatch(getAllChatsAction(`?userID=${user.id}`));
    }
  };

  const messageListener = useCallback(
    ({ newMessage, chatID, users, adminID }) => {
      if (chatID === selectedChat?.id) {
        dispatch({
          type: "UPDATE_SELECTED_CHAT",
          payload: { newMessage, chatID },
        });
      } else {
        dispatch({
          type: "UPDATE_CHATS_LIST_MESSAGE",
          payload: { newMessage, chatID, users, adminID },
        });
      }
    },

    [dispatch, selectedChat?.id]
  );

  useEffect(() => {
    const newSocket = io("https://getloanr.com", {
      cookie: true,
      transports: ["polling", "websocket"],
      extraHeaders: {
        "Access-Control-Allow-Origin": "*",
      },
      reconnection: true,
    });
    newSocket.on("connect", () => {
      newSocket.emit("add user", user);
      setSocket(newSocket);
    });
    return () => newSocket.close();
  }, [user, setSocket]);

  useEffect(() => {
    if (socket) {
      socket.emit("get_notifications", user.id);
      socket.on("set_notifications", (notifications) => {
        dispatch({
          type: "SET_INITIAL_NOTIFICATIONS",
          payload: { notifications },
        });
      });
      socket?.removeAllListeners("new-notifications");
      socket?.on("new-notifications", (newNotifications) => {
        dispatch({ type: "ADD_NOTIFICATIONS", payload: { newNotifications } });
      });
      if (!user.isPhoneNumberVerified && appSettings.enableTwilioVerification) {
        socket.on("sms-code-status-changed", (response) => {
          if (response.status === "failed") {
            toast.error("Sending sms failed. Try to resend");
          }
          if (response) {
            setVerificationSMS(response);
          }
        });

        socket.on("sms-code-verifying-result", (responce) => {
          if (responce.status) {
            handleResetVerificationSMS();
            setOpenVerificationDialog(false);
            dispatch({
              type: "UPDATE PROFILE",
              data: { ...user, isPhoneNumberVerified: true },
            });

            toast("Phone number verified");
          } else {
            toast.error("Code is not correct");
          }
        });
      }
    }
  }, [socket, user, dispatch, appSettings.enableTwilioVerification]);

  useEffect(() => {
    socket?.removeAllListeners("check-is-notification-required");
    socket?.on(
      "check-is-notification-required",
      ({ chatID, sender, receiver, type }) => {
        if (selectedChat?.id !== chatID) {
          socket?.emit("create-notification", { sender, receiver, type });
        }
      }
    );
  }, [selectedChat?.id, socket, dispatch]);

  useEffect(() => {
    socket?.removeAllListeners("new-message");
    socket?.on("new-message", messageListener);
  }, [socket, messageListener, dispatch]);

  useEffect(() => {
    handlePrefetchChats();
  }, []);

  useEffect(() => {
    if (!openVirificationDialog && appSettings.enableTwilioVerification)
      handleResetVerificationSMS();
  }, [openVirificationDialog, appSettings.enableTwilioVerification]);

  useEffect(() => {
    if (user.isTipsShown === undefined) {
      setOpenTipsModal(true);
    }
  }, [user.isTipsShown]);

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAN_CHATS" });
    };
  }, []);

  return (
    <Box style={{ display: "flex" }}>
      {isLoansLoading && (
        <Grid
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.7)",
            zIndex: 1301,
          }}
        >
          <ClipLoader color="#4267b2" size={110} />
          <p style={{ color: "#4267b2" }}>Please Wait...</p>
        </Grid>
      )}
      <CssBaseline />
      <TipsModal
        isTipsModalOpen={openTipsModal}
        handleCloseTipsModal={handleInfoBtnClick}
        user={user}
      />
      <ReportForm
        isReportFormOpen={isReportFormOpen}
        handleCloseReportForm={handleCloseReportForm}
        handleSubmitReport={handleSubmitReport}
        reportFormData={reportFormData}
        handleReportFormChange={handleReportFormChange}
      />
      <VerificationDialog
        open={openVirificationDialog}
        handleClose={() => setOpenVerificationDialog(false)}
        handleSendSmsCode={handleSendRequestSMSCode}
        verificationSmsStatus={verificationSMS.status}
        handleVerifySmsCode={handleVerifySmsCode}
      />
      <AppBar
        open={openDrawer}
        sx={{ backgroundColor: "#e7efff", boxShadow: "none", zIndex: 1200 }}
      >
        <Toolbar sx={{ padding: "0px 16px !important" }}>
          <Grid item container alignItems={"center"}>
            {" "}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(openDrawer && { display: "none" }),
              }}
            >
              <MenuIcon style={{ color: "black", fontSize: "1.1em" }} />
            </IconButton>
          </Grid>

          <Box
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {/* Send report Button */}
            <IconButton onClick={() => setIsReportFormOpen(true)}>
              <ReportProblemOutlinedIcon />
            </IconButton>
            {/* Info Button */}
            <IconButton onClick={handleInfoBtnClick}>
              <InfoOutlinedIcon />
            </IconButton>
            {/* Notification Button */}
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={menu ? "long-menu" : undefined}
              aria-expanded={menu ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              style={{ marginRight: "1em" }}
            >
              <Badge badgeContent={notifications?.length} color="primary">
                <img src={notif} alt="notif" />
              </Badge>
            </IconButton>
            <NotificationsList
              notifications={notifications}
              handleNotificationClick={handleNotificationClick}
              anchorEl={anchorEl}
              handleClose={handleClose}
              menu={menu}
            />
            <IconButton
              component={Link}
              to="/dashboard/settings"
              color="inherit"
              sx={{ padding: 0 }}
            >
              <Avatar>{user.username[0]}</Avatar>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        onClose={() => toggleDrawer()}
        open={openDrawer}
        sx={{
          zIndex: 1200,
          width: small ? "40vw" : "20vw",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: small ? "65vw" : "20vw",
            boxSizing: "border-box",
          },
        }}
        variant={!mid ? "permanent" : "temporary"}
        anchor="left"
      >
        <Box sx={{ px: 2, py: 1 }}>
          <Link to="/dashboard">
            <img
              src="/logo.svg"
              style={{ width: small ? "20vw" : "10vw" }}
              alt="logo"
            />
          </Link>
        </Box>
        <Toolbar />
        <Box>
          <Button
            fullWidth
            style={{
              color: "black",
              padding: 0,
              marginBottom: "1em",
              textTransform: "capitalize",
            }}
          >
            <NavLink
              onClick={toggleDrawer}
              end
              to="/dashboard"
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(124, 154, 234,0.7)",
                      color: "black",
                      textDecoration: "none",
                      height: "100%",
                      width: "100%",
                      padding: "0.5em",
                    }
                  : {
                      color: "black",
                      textDecoration: "none",
                      height: "100%",
                      width: "100%",
                      padding: "0.5em",
                    }
              }
            >
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={3}
                  container
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <DashboardOutlinedIcon />
                </Grid>
                <Grid item xs={7} container>
                  <Typography fontSize={24}>Dashboard</Typography>
                </Grid>
              </Grid>
            </NavLink>
          </Button>
          <Button
            fullWidth
            style={{
              color: "black",
              padding: 0,
              marginBottom: "1em",
              textTransform: "capitalize",
            }}
          >
            <NavLink
              onClick={toggleDrawer}
              to="/dashboard/messages"
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(124, 154, 234,0.7)",
                      color: "black",
                      textDecoration: "none",
                      height: "100%",
                      width: "100%",
                      padding: "0.5em",
                    }
                  : {
                      color: "black",
                      textDecoration: "none",
                      height: "100%",
                      width: "100%",
                      padding: "0.5em",
                    }
              }
            >
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={3}
                  container
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <MessageOutlinedIcon />
                </Grid>
                <Grid item xs={7} container>
                  <Typography fontSize={24}>Messages</Typography>
                </Grid>
              </Grid>
            </NavLink>
          </Button>
          <Button
            fullWidth
            style={{
              color: "black",
              padding: 0,
              marginBottom: "1em",
              textTransform: "capitalize",
            }}
          >
            <NavLink
              onClick={toggleDrawer}
              to="/dashboard/loan-board"
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(124, 154, 234,0.7)",
                      color: "black",
                      textDecoration: "none",
                      height: "100%",
                      width: "100%",
                      padding: "0.5em",
                    }
                  : {
                      color: "black",
                      textDecoration: "none",
                      height: "100%",
                      width: "100%",
                      padding: "0.5em",
                    }
              }
            >
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={3}
                  container
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <img src={pie} alt="" />
                </Grid>
                <Grid item xs={7} container>
                  <Typography fontSize={24}>LoanBoard</Typography>
                </Grid>
              </Grid>
            </NavLink>
          </Button>{" "}
          <Button
            fullWidth
            style={{
              color: "black",
              padding: 0,
              marginBottom: "1em",
              textTransform: "capitalize",
            }}
          >
            <NavLink
              onClick={toggleDrawer}
              to="/dashboard/settings"
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(124, 154, 234,0.7)",
                      color: "black",
                      textDecoration: "none",
                      height: "100%",
                      width: "100%",
                      padding: "0.5em",
                    }
                  : {
                      color: "black",
                      textDecoration: "none",
                      height: "100%",
                      width: "100%",
                      padding: "0.5em",
                      display: "flex",
                    }
              }
            >
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={3}
                  container
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <img src={gear} alt="" />
                </Grid>
                <Grid item xs={7} container>
                  <Typography fontSize={24}>Settings</Typography>
                </Grid>
              </Grid>
            </NavLink>
          </Button>{" "}
        </Box>
        <Box
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              dispatch(logout(navigate, toggleDrawer));
            }}
            style={{
              color: "black",
              padding: "0.5em",
              marginBottom: "1em",
              textTransform: "capitalize",
            }}
          >
            <Grid item container xs={12} alignItems="center">
              <Grid
                item
                xs={3}
                container
                justifyContent={"center"}
                alignItems="center"
              >
                <LogoutOutlinedIcon />
              </Grid>
              <Grid container item xs={7}>
                <Typography fontSize={24}>Logout</Typography>
              </Grid>
            </Grid>
          </Button>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          backgroundColor: "#e7efff",
          p: {
            xs: 2,
            md: "0 1rem",
          },
        }}
      >
        <Toolbar />
        {!user.isPhoneNumberVerified &&
          appSettings.enableTwilioVerification && (
            <Alert
              severity="warning"
              action={
                <Button
                  onClick={() => setOpenVerificationDialog(true)}
                  color="inherit"
                  size="small"
                >
                  Verify
                </Button>
              }
            >
              You need to verify phone number
            </Alert>
          )}
        <DeleteLoan
          open={isOpenDeleteModal}
          setOpen={setIsOpenDeleteModal}
          deleteLoan={deleteLoanHandler}
        />
        <Routes>
          <Route
            path=""
            element={
              <Home
                deleteLoanBtnClick={deleteLoanBtnClick}
                getDashboardData={getDashboardData}
                socket={socket}
              />
            }
          />
          <Route
            path="loan-board/*"
            element={
              <Board deleteLoanBtnClick={deleteLoanBtnClick} socket={socket} />
            }
          />
          <Route path="messages" element={<ChatScreen socket={socket} />} />
          <Route path="settings/*" element={<Settings />} />
          <Route
            path="/profile/:id"
            element={<ProfilePage socket={socket} />}
          />
        </Routes>
      </Box>
    </Box>
  );
}

export default Dashboard;

/*Verification Dialog Window */

const VerificationDialog = ({
  open,
  handleClose,
  handleSendSmsCode,
  verificationSmsStatus,
  handleVerifySmsCode,
}) => {
  const [smsCode, setSmsCode] = useState("");
  const theme = useTheme();

  const modalText = document.getElementById("modal-text");
  const modalInput = document.getElementById("modal-input");

  const isSmsFailed = verificationSmsStatus === "failed";
  const actionTitle = !verificationSmsStatus
    ? "Send"
    : isSmsFailed
    ? "Resend Code"
    : "Verify";
  const currentActionCallback =
    actionTitle === "Verify"
      ? () => handleVerifySmsCode(smsCode)
      : handleSendSmsCode;

  const resetAnimationStyles = useCallback(() => {
    if (modalInput) {
      modalText.style.transform = "translateX(0)";
      modalInput.style.transform = "translateX(-1000px)";
    }
  }, [modalInput, modalText?.style]);

  const handleDialogClose = useCallback(() => {
    handleClose();
    setSmsCode("");
    resetAnimationStyles();
  }, [handleClose, setSmsCode, resetAnimationStyles]);
  useEffect(() => {
    if (!open) {
      handleDialogClose();
    }
  }, [open, handleDialogClose]);

  return (
    <>
      <Backdrop open={open} onClose={handleClose} sx={{ zIndex: 100 }}>
        <Box
          sx={{
            width: "340px",
            backgroundColor: "white",
            zIndex: 110,
            p: "16px",
            overflow: "hidden",
          }}
        >
          <Typography
            component="h5"
            sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
          >
            Phone Verification
          </Typography>
          <Grid sx={{ mt: 1, position: "relative" }} container>
            <Grid
              sx={{
                fontSize: "1rem",
                transition: "all ease-in-out 450ms",
              }}
              id="modal-text"
            >
              <Typography>
                Verification code will be sent to your phone
              </Typography>
            </Grid>

            <Grid
              id="modal-input"
              sx={{
                position: "absolute",
                transform: "translateX(-1000px)",
                transition: "all ease-in-out 350ms 100ms",
              }}
            >
              <TextField
                fullWidth
                placeholder="Code from SMS"
                size="small"
                variant="outlined"
                value={smsCode}
                onChange={(e) => setSmsCode(e.target.value.trim())}
              />

              <FormHelperText>
                SMS Code was -{" "}
                <span
                  style={{
                    color: isSmsFailed ? "red" : theme.palette.primary.main,
                  }}
                >
                  {verificationSmsStatus}
                </span>
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container sx={{ justifyContent: "flex-end" }}>
            <Button onClick={handleClose}>Close</Button>
            <Button
              onClick={() => {
                if (!verificationSmsStatus) {
                  modalText.style.transform = "translateX(1000px)";
                  modalInput.style.transform = "translateX(0)";
                }
                currentActionCallback();
              }}
              autoFocus
            >
              {actionTitle}
            </Button>
          </Grid>
        </Box>
      </Backdrop>
    </>
  );
};
