import React from "react";
import { Collateral } from "../../Components/Collateral";

import { Button, Grid, Typography } from "@mui/material";
import moment from "moment";

const Cards = ({
  loan,
  handleOnMessageClick,
  setSelectedLoanID,
  handleReview,
}) => {
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        border: "1px solid rgba(66, 103, 178,0.3)",
        boxShadow: "0px 1px 6px 0px #7C9AEA",
        padding: "1em",
        borderRadius: "6px",
        width: {
          xs: "100%",
          md: "fit-content",
        },
        maxWidth: {
          xs: "100%",
          md: "500px",
        },
      }}
    >
      <Grid
        id={loan.id}
        container
        alignItems={{
          xs: "flex-start",
          md: "center",
        }}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          marginBottom: "1em",
          justifyContent: "flex-start",
          width: "fit-content",
          gap: "5px",
        }}
      >
        <Grid item>
          <Typography
            fontWeight={400}
            fontSize={"15px"}
            sx={{ fontWeight: "bold" }}
          >
            [{loan.status.toUpperCase()}]
          </Typography>
        </Grid>

        <Grid item>
          <Typography fontWeight={400} fontSize={"15px"}>
            Payback date: {moment(loan.date).format("MMM DD,yyyy")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontWeight={400} fontSize={"15px"}>
            Username: {loan?.created_by?.username || "deleted"}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: "inline-flex",
            alignItems: "baseline",
            gap: "10px",
          }}
        >
          <Typography fontWeight={400} fontSize={"15px"}>
            Collateral:
          </Typography>
          <Collateral collateral={loan.collateral} />
        </Grid>
        <Grid item>
          <Typography
            fontWeight={400}
            fontSize={"15px"}
            sx={{ width: "fit-content", margin: 0 }}
          >
            Requested Amount: ${loan.requestedAmount}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={{ display: "flex", gap: "8px", margin: { xs: "auto" } }}>
        <Button
          variant="outlined"
          onClick={() => handleOnMessageClick(loan.created_by.id)}
        >
          Message
        </Button>
        {loan.status === "published" && (
          <Button variant="outlined" onClick={setSelectedLoanID}>
            Refund
          </Button>
        )}
        {loan.status == "pending" && (
          <Button variant="outlined" onClick={() => handleReview(loan.id)}>
            Review
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default Cards;
