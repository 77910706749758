export const getStyles = () => ({
  wrapper: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "1em 1.5em",
    borderRadius: "8px",
    maxHeight: "90%",
    maxWidth: "500px",
    width: "fit-content",
    overflowY: "auto",
  },
  buttonsContainer: {
    width: "100%",
    justifyContent: {
      xs: "center",

      md: "flex-end",
    },
    ml: {},
  },
  buttonsWrapper: {
    height: "fit-content",
    display: "flex",
    gap: 1,
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  cancelButton: {
    margin: "8px auto 0 auto",
    fontSize: "16px",
  },
  publishButton: {
    mr: 1,
    mt: 1,
    fontSize: { xs: "16px", md: "14px" },
    order: { xs: 1, sm: 3 },
    flexBasis: { xs: "60%", sm: "auto" },
  },
});
