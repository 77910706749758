export const appSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_APP_SETTINGS":
      state = {
        ...state,
        ...action.appSettings,
      };
      return state;
    case "UPDATE_APP_SETTINGS":
      state = {
        ...state,
        ...action.appSettings,
      };
      return state;

    default:
      return state;
  }
};
