import React, { useCallback, useState, memo, useMemo } from "react";

import { Button, Grid, Typography } from "@mui/material";

import { UploadButton } from "./UploadButton";
import { toast } from "react-toastify";

const PAYSTUBS_MAX = 2;

export const PaystubsUploadForm = memo(
  ({ requestState, setRequestState, setErrors, closeForm, theme }) => {
    const [paystubFiles, setPaystubFiles] = useState(requestState.paystubFiles);
    const [existedPaystubs, setExistedPaystubs] = useState(
      requestState.collateral.paystubs || []
    );
    const allPaystubs = useMemo(
      () => paystubFiles.concat(existedPaystubs),
      [paystubFiles, existedPaystubs]
    );

    const handleUploadPaystubImages = useCallback(
      (e, type) => {
        const { files } = e.target;

        if (allPaystubs.length + files.length > PAYSTUBS_MAX) {
          return toast.info(`You can upload up to ${PAYSTUBS_MAX} image/s`);
        }

        let paystubsUploads = [...paystubFiles];

        Object.values(files).forEach(async (file) => {
          paystubsUploads.push({
            id: Math.random().toString(),
            data: file,
            type,
          });
        });

        setPaystubFiles(paystubsUploads);
      },
      [allPaystubs]
    );

    const handleDeletePaystub = (imageId, imageURL) => {
      if (imageURL) {
        const updatedExistedPaystubs = existedPaystubs.filter(
          (image) => image.url !== imageURL
        );
        return setExistedPaystubs(updatedExistedPaystubs);
      } else if (imageId) {
        const updatedPaystubFiles = paystubFiles.filter(
          (image) => image.id !== imageId
        );
        return setPaystubFiles(updatedPaystubFiles);
      }
    };

    const submitPaystubs = () => {
      if (existedPaystubs.length === PAYSTUBS_MAX) {
        return closeForm();
      }
      if (allPaystubs.length < PAYSTUBS_MAX) {
        return toast.info(`Please add paystubs`);
      }
      if (paystubFiles.length) {
        setRequestState((prevState) => ({
          ...prevState,
          paystubFiles,
          collateral: { paystubs: existedPaystubs },
          isCollateralExist: true,
        }));
        setErrors((prevState) => ({ ...prevState, isCollateralExist: false }));

        closeForm();
      } else {
        toast("Please add paystubs");
      }
    };
    return (
      <Grid
        container
        sx={{
          flexDirection: "column",
          gap: "7px",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          width: "100%",
          border: "1px solid rgba(66, 103, 178,0.3)",
          boxShadow: "0px 1px 6px 0px #7C9AEA",
          borderRadius: "6px",
          padding: "1em",
        }}
      >
        <Typography sx={{ color: "rgba(0,0,0,.6)" }}>
          Please attach your last 2 paystubs. This{" "}
          <span style={{ fontWeight: "bold" }}>
            will not be publicly displayed
          </span>{" "}
          and, if verified and approved, will be used to grant you a loan within
          24 hours.
        </Typography>
        <Grid
          container
          item
          sx={{
            flexDirection: "row",
            padding: "5px",
            mt: "8px",
            gap: "5px",
            borderRadius: "8px",
            width: "fit-content",
            justifyContent: "center",
            alignItems: "center",
            border: allPaystubs.length && `1px solid rgba(0,0,0,.6)`,
          }}
        >
          {!!allPaystubs.length &&
            allPaystubs.map((image) => {
              return (
                <Grid
                  key={image.id}
                  item
                  sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    justifyContect: "center",
                    alignItems: "center",
                    "&:not(:first-of-type)": { marginLeft: 1 },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "14px", color: "rgba(0,0,0,.7)" }}
                  >
                    {image.data?.name ||
                      image?.originalname ||
                      image?.data?.originalname}
                  </Typography>
                  <Typography
                    onClick={() => handleDeletePaystub(image.id, image?.url)}
                    sx={{
                      fontSize: "14px",
                      color: theme.palette.warning.main,
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    Delete
                  </Typography>
                </Grid>
              );
            })}
        </Grid>

        {allPaystubs.length < PAYSTUBS_MAX && (
          <UploadButton
            caption="Upload"
            handleUploadImage={handleUploadPaystubImages}
            type="utilityBill"
            styles={{
              display: "inline-block",
              margin: "0 auto",
            }}
          />
        )}
        <Grid
          container
          sx={{
            justifyContent: "flex-end",
            gap: "0.5rem",
            mt: {
              xs: "1rem",
              md: 0,
            },
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setPaystubFiles([]);
              closeForm();
            }}
          >
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={submitPaystubs}>
            Next
          </Button>
        </Grid>
      </Grid>
    );
  }
);
