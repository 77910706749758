import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { updateAppSettings } from "../../actions/admin";
import { getAppSettingsAction } from "../../actions/services";

export const AppSettings = () => {
  const { appSettings } = useSelector((state) => state);
  const dispatch = useDispatch();

  const onChange = ({ target }) => {
    dispatch(
      updateAppSettings({
        ...appSettings,
        [target.name]: !appSettings[target.name],
      })
    );
  };
  useEffect(() => {
    dispatch(getAppSettingsAction());
  }, []);

  return (
    <Grid container sx={{ display: "flex", paddingRight: "1.5em" }}>
      <Grid
        container
        item
        sx={{
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          p: "8px",
        }}
      >
        <Typography fontSize={20}>Is collateral required?</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="enableCollateral"
                checked={appSettings.enableCollateral}
                onChange={onChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        </FormGroup>
      </Grid>
      <Grid
        container
        item
        sx={{
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          p: "8px",
        }}
      >
        <Typography fontSize={20}>Is loan request review enabled?</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="enableLoanRequestReview"
                checked={appSettings.enableLoanRequestReview}
                onChange={onChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};
