import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Grid, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { LinkToProfile } from "..";
import moment from "moment";
import { RequestReviewComment } from "./RequestReviewComment";
import { ActionButtons } from "./ActionButtons";
import { downloadAgreementAPI } from "../../api/loan";
import { toast } from "react-toastify";

export const LoanCard = ({
  loan,
  isShort,
  styles,
  backLocation,
  target,
  isLoanRequestReviewEnabled,
  ...actionButtonsHandlers
}) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);

  const isOwnLoan = loan.created_by?.id === user.id;

  let mainLoanStatus;
  switch (loan.status) {
    case "granted":
      mainLoanStatus = "IN PROGRESS";
      break;
    case "preconfirmed":
      mainLoanStatus = "IN PROGRESS";
      break;
    case "paid":
      mainLoanStatus = "COMPLETED";
      break;
    default:
      mainLoanStatus = "REQUEST";
  }

  const isGrantingConfirmed = loan.isGrantingConfirmed;

  const isLoanRefunded = loan.status === "refunded";
  const isLoanDeclined = loan.status === "declined";

  const isLoanBoard = window.location.pathname.includes("loan-board");

  const navigate = useNavigate();
  const theme = useTheme();

  const reviewReceiver = isOwnLoan ? loan.granted_by : loan.created_by;

  const downLoadLoanAgreement = async (loanID) => {
    dispatch({ type: "LOAD" });
    try {
      const resp = await downloadAgreementAPI(loanID);
      const href = URL.createObjectURL(resp.data);

      // create "a" HTML element with href to download the file
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `loan-agreement_${loan.created_by.username}-${loan.granted_by.username}.pdf`
      );
      //   Remove link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      toast("Something went wrong");
    } finally {
      dispatch({ type: "UNLOAD" });
    }
  };

  return (
    <Grid
      id={loan.id}
      container
      sx={{
        flexDirection: "column",
        position: "relative",
        width: "600px",
        height: "fit-content",
        maxWidth: "100%",
        padding: "8px",
        backgroundColor: "white",
        border: "1px solid rgba(66, 103, 178,0.3)",
        boxShadow: loan?.granted_by
          ? "rgb(113 137 201) 0px 0px 10px 2px"
          : "0px 1px 6px 0px #7C9AEA",
        borderRadius: "6px",
        margin: "0 auto 8px auto",
        ...styles,
      }}
    >
      {!isShort && (
        <>
          <Grid container>
            {/* Borrower username */}
            <Grid container item sx={{ width: "fit-content" }}>
              <Typography sx={{ color: "#787C7E", fontSize: "12px" }}>
                Posted by{" "}
              </Typography>
              {!isOwnLoan ? (
                <LinkToProfile
                  user={loan.created_by}
                  backLocation={backLocation}
                  target={target}
                />
              ) : (
                <Typography fontSize="13px" sx={{ m: "0 2px" }}>
                  {"@" + loan.created_by?.username}
                </Typography>
              )}
              {/* Published Date */}
              <Typography sx={{ color: "#787C7E", fontSize: "12px" }}>
                {", " + moment.utc(loan.createdAt).format("MMM/DD/YY")}
              </Typography>
            </Grid>
            {isLoanRefunded && (
              <Grid
                sx={{
                  ml: "auto",
                  boxShadow: `0px 1px 5px 1px ${theme.palette.primary.main}`,
                  borderRadius: "8px",
                  p: "6px",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Fee Refunded
                </Typography>
              </Grid>
            )}
          </Grid>
          {/* Fulfilled Badge */}
          {isGrantingConfirmed && isLoanBoard && (
            <Grid
              container
              item
              sx={{
                width: "fit-content",
                height: "fit-content",
                padding: "1px 5px",
                alignItems: "center",
                boxShadow: `0px 1px 5px 1px ${theme.palette.primary.main}`,
                margin: "5px auto 0 auto",
              }}
            >
              <Typography
                fontSize={"13px"}
                fontWeight={400}
                sx={{ marginRight: "2px" }}
              >
                Fulfilled Loan by
              </Typography>
              <LinkToProfile
                user={loan.granted_by}
                backLocation={backLocation}
                target={target}
              />
            </Grid>
          )}
        </>
      )}

      <Grid
        container
        sx={{
          width: "fit-content",
          mt: 1,
          cursor: isShort && "pointer",
        }}
        onClick={(e) => {
          if (e.target.parentNode.tagName !== "BUTTON" && isShort)
            navigate(`/dashboard/loan-board`, { state: { target: loan.id } });
        }}
      >
        {/* General Loan Status */}
        <Typography>
          <span
            style={{
              backgroundColor: "rgb(225 229 235)",
              borderRadius: "20px",
              padding: "2px 8px 2px 0",
              color: "#000000",
              fontWeight: "600",
              marginRight: "3px",
              textTransform: "uppercase",
            }}
          >
            {/* Specific Loan Status */}
            {mainLoanStatus}
          </span>
          <span style={{ textTransform: "uppercase" }}>
            [{loan.status === "refunded" ? "fee refunded" : loan.status}]
          </span>
          (${loan.requestedAmount}) - (#{loan.borrowerLocation}),
          {loan.collateral?.short &&
            `(Collateral -
          ${loan?.collateral?.short}),`}{" "}
          (<strong>Repay</strong> ${loan.paybackAmount} by{" "}
          {moment.utc(loan.date).format("MMM Do, YYYY")}){/* , ( */}
        </Typography>
      </Grid>
      {loan?.requestReviewComment && isOwnLoan && (
        <RequestReviewComment
          requestReviewComment={loan.requestReviewComment}
        />
      )}
      {/* Action Buttons */}
      {!isLoanRefunded && !isLoanDeclined && (
        <ActionButtons
          loan={loan}
          handlers={actionButtonsHandlers}
          isLoanBoard={isLoanBoard}
          isOwnLoan={isOwnLoan}
          isLoanRefunded={isLoanRefunded}
          isGrantingConfirmed={isGrantingConfirmed}
          reviewReceiver={reviewReceiver}
          navigate={navigate}
          isReviewFormOpen={isReviewFormOpen}
          setIsReviewFormOpen={setIsReviewFormOpen}
          isShort={isShort}
          user={user}
          isLoanRequestReviewEnabled={isLoanRequestReviewEnabled}
          downLoadLoanAgreement={() => downLoadLoanAgreement(loan.id)}
        />
      )}
    </Grid>
  );
};
