import React from "react";

import { Grid, Typography } from "@mui/material";

import { LoanCardButton } from "../LoanCardButton";
import { ReviewForm } from "../ReviewForm";

import moment from "moment";

export const ActionButtons = ({
  loan,
  handlers,
  isLoanBoard,
  isOwnLoan,
  isLoanRefunded,
  isGrantingConfirmed,
  reviewReceiver,
  navigate,
  isReviewFormOpen,
  setIsReviewFormOpen,
  isShort,
  user,
  isLoanRequestReviewEnabled,
  downLoadLoanAgreement,
}) => {
  const isLoanPreconfirmed = loan.granted_by;
  const isDownloadButtonVisible = ["granted", "paid", "unpaid"].includes(
    loan.status
  );
  const isLoanRepaid = loan.isRepaid;
  const isReviewLeft = loan.reviews?.includes(user.id);
  const userIsLender = loan.granted_by?.id === user.id;
  const isLoanUnpaid = loan.status === "unpaid";
  const isDateChangeRequested = loan.paybackDateRequest?.status === "pending";
  const isLoanPending = loan.status === "pending";
  const isLoanAccepted = loan.status === "accepted";
  const isLoanSaved = loan.status === "saved";

  const isBorrowerDashboard = !isLoanBoard && isOwnLoan;

  return (
    <Grid
      container
      sx={{
        m: "8px auto 0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Borrower dashboard buttons */}
      {isBorrowerDashboard && !isLoanPreconfirmed && !isLoanRefunded && (
        <Grid
          item
          container
          sx={{ width: "fit-content", gap: "5px", margin: "0 auto" }}
        >
          {!isLoanPending && (
            <>
              <LoanCardButton
                title="Delete"
                btnProps={{ color: "warning" }}
                cb={() => handlers.deleteLoanBtnClick(loan.id)}
              />

              <LoanCardButton
                title="Edit"
                cb={() => handlers.editLoanHandler(loan)}
              />
              {isLoanSaved && (
                <LoanCardButton
                  title={
                    isLoanRequestReviewEnabled ? "Send to review" : "Publish"
                  }
                  cb={handlers.processSavedLoan}
                />
              )}
            </>
          )}
          {handlers.processSavedLoan && isLoanAccepted && (
            <LoanCardButton title="Publish" cb={handlers.processSavedLoan} />
          )}
        </Grid>
      )}

      {/* Borrower preconfirmed request buttons */}
      {isBorrowerDashboard && isLoanPreconfirmed && !isGrantingConfirmed && (
        <Grid
          container
          item
          sx={{
            width: "fit-content",
            m: "3px auto 0 auto",
            gap: "5px",
            border: isDateChangeRequested && "1px solid #ccc",
            borderRadius: isDateChangeRequested && "8px",
            padding: isDateChangeRequested && { xs: "5px", sm: "7px" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoanCardButton
            title="Confirm"
            cb={() => handlers.processGrantedLoan("confirm", loan)}
          />
          <LoanCardButton
            title="Deny"
            cb={() => handlers.processGrantedLoan("deny-grant", loan)}
            btnProps={{ color: "warning" }}
          />
        </Grid>
      )}

      {/* Borrower extend payback daye buttons */}
      {isBorrowerDashboard && isDateChangeRequested && (
        <Grid
          sx={{
            display: "flex",
            gap: "5px",
            border: "1px solid #ccc",
            p: "5px",
          }}
        >
          <ExtendDateInfoMessage
            paybackDateRequest={loan.paybackDateRequest.date}
          />
          <LoanCardButton
            title="Confirm"
            cb={() =>
              handlers.handleExtendPaybackDate(
                "confirm-extend-date",
                loan,
                true
              )
            }
          />
          <LoanCardButton
            btnProps={{ color: "warning" }}
            title="Cancel"
            cb={() =>
              handlers.handleExtendPaybackDate("cancel-extend-date", loan, true)
            }
          />
        </Grid>
      )}
      {/* Lender loanboard buttons */}
      {!isOwnLoan && !isLoanPreconfirmed && (
        <Grid container sx={{ justifyContent: "center", gap: "5px" }}>
          {!isShort && (
            <LoanCardButton
              btnProps={{ disabled: !loan.created_by }}
              title="Message"
              cb={() => handlers.handleOnMessageClick(loan.created_by.id)}
            />
          )}
          {handlers.grantLoanHandler && (
            <LoanCardButton
              btnProps={{ disabled: !loan.created_by }}
              title="Grant Loan"
              cb={() => handlers.grantLoanHandler(loan)}
            />
          )}
          {!isShort && (
            <LoanCardButton
              btnProps={{ disabled: !loan.created_by }}
              title={
                loan?.collateral?.title ? "Viev Collateral" : "Request Paystubs"
              }
              cb={
                loan?.collateral?.title
                  ? () => handlers.onViewCollateralClick(loan)
                  : () => handlers.handleRequestPaystubs(loan.created_by)
              }
            />
          )}
        </Grid>
      )}

      {/* Lender dashboard Buttons */}
      {isGrantingConfirmed &&
        !isLoanBoard &&
        !isLoanRepaid &&
        !isLoanUnpaid &&
        userIsLender && (
          <>
            {isDateChangeRequested && (
              <Grid
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: { xs: "5px", sm: "7px" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ExtendDateInfoMessage
                  paybackDateRequest={loan.paybackDateRequest.date}
                  isLender
                />
              </Grid>
            )}
            <Grid item container sx={{ width: "fit-content", gap: "5px" }}>
              <LoanCardButton
                title="Repaid"
                cb={() =>
                  navigate(`/dashboard/loan-board/${loan.id}/repaid`, {
                    state: { loan },
                  })
                }
                centered
              />

              {moment(loan.date).isSameOrBefore(moment()) && !isShort && (
                <LoanCardButton
                  title="Extend Payback"
                  cb={() =>
                    handlers.handleExtendPaybackDate("extend-date", loan, false)
                  }
                  centered
                />
              )}
              <LoanCardButton
                title="Not Repaid"
                btnProps={{
                  color: "warning",
                }}
                cb={handlers.handleLoanNotRepaid}
                centered
              />
            </Grid>
          </>
        )}

      {/* Review button */}
      {((isLoanRepaid && !isLoanBoard && !isReviewLeft) ||
        (loan.status === "unpaid" && userIsLender && !isReviewLeft)) && (
        <Grid
          container
          sx={{
            ml: !isOwnLoan && "5px",
            width: isReviewFormOpen ? "100%" : "fit-content",
          }}
        >
          {!isReviewFormOpen ? (
            <LoanCardButton
              title="Leave review"
              cb={() => setIsReviewFormOpen(true)}
            />
          ) : (
            <ReviewForm
              user={user}
              loanID={loan.id}
              authorRole={isOwnLoan ? "borrower" : "lender"}
              closeReviewForm={() => setIsReviewFormOpen(false)}
              reviewReceiver={reviewReceiver}
              isOnLoanCard={true}
              postReviewCallback={handlers.postReviewCallback}
            />
          )}
        </Grid>
      )}

      {/* Download agreement button */}
      {!isLoanBoard && isDownloadButtonVisible && !isReviewFormOpen && (
        <Grid
          sx={{
            ml: "5px",
          }}
        >
          <LoanCardButton
            btnProps={{ disabled: !loan.created_by }}
            title="Download Agreement"
            cb={downLoadLoanAgreement}
          />
        </Grid>
      )}
    </Grid>
  );
};

const ExtendDateInfoMessage = ({ paybackDateRequest, isLender }) => {
  return (
    <Typography>
      New payback date:{" "}
      <strong>{moment.utc(paybackDateRequest).format("MMM Do, YYYY")}</strong>
      {isLender && "- waiting for Borrower confirmation"}
    </Typography>
  );
};
