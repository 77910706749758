import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";

import { updateLoan } from "../../../actions/loan";

import { EditLoanModal } from "./EditLoanModal";

import { toast } from "react-toastify";
import { LoanRequestForm } from "../components/LoanRequestForm";
import { StripePayments } from "../../../Components/Payments/Stripe/StripePyaments";

const EditRequest = ({ socket }) => {
  const { state } = useLocation();

  const [requestState, setRequestState] = useState({
    ...state.loan,
    isLoanEditing: true,
    ...(state.loan.collateral.paystubs
      ? { paystubFiles: [] }
      : { collateralFiles: [] }),
  });

  const [showEditModal, setShowEditModal] = useState(false);
  const [revise, setRevise] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getRequestFormData = useCallback(() => {
    const formData = new FormData();

    for (let key in requestState) {
      if (key === "collateral" && requestState[key]) {
        formData.append(key, JSON.stringify(requestState[key]));
      } else if (key === "utilityBills" && requestState[key].length) {
        requestState[key].forEach((item) => {
          formData.append("utilityBills", JSON.stringify(item));
        });
      } else if (key === "collateralFiles" && requestState[key]) {
        for (let image of requestState[key]) {
          formData.append(key, image.data);
        }
      } else if (key === "paystubFiles" && requestState[key]) {
        for (let image of requestState[key]) {
          formData.append(key, image.data);
        }
      } else if (key === "utilityBillFiles" && requestState[key]) {
        for (let image of requestState[key]) {
          formData.append(key, image.data);
        }
      } else {
        formData.append(key, requestState[key]);
      }
    }
    return formData;
  }, [requestState]);

  const handleCloseTermsModal = useCallback(() => {
    setShowEditModal(false);
    setRevise(null);
  }, [setShowEditModal]);

  const handleSubmitLoanEdit = useCallback(() => {
    const formData = getRequestFormData();

    dispatch({ type: "LOAD" });
    if (state) {
      formData.set("isLoanEditing", "false");
      dispatch(
        updateLoan(state.loanId, "edit-finish", formData, ({ data }) => {
          if (data.action === "surcharge") {
            setRevise(data);
            setShowEditModal(true);
            return;
          }
          if (data.id) {
            setRequestState(null);
            setShowEditModal(false);
            navigate("/dashboard/", {});
            toast.success("Loan request has been updated successfully!");
          }
        })
      );
      return;
    }
  }, [state, navigate, getRequestFormData, dispatch]);

  const cancelLoanEditingHandler = useCallback(() => {
    dispatch(updateLoan(state.loanId, "edit", { isLoanEditing: false }));
    navigate("/dashboard");
  }, [dispatch, navigate, state.loanId]);

  useEffect(() => {
    return () => {
      state?.loanId && cancelLoanEditingHandler();
    };
  }, [state?.loanId, cancelLoanEditingHandler]);

  useEffect(() => {
    socket?.removeAllListeners("subscription-paid");
    socket?.on("subscription-paid", ({ message }) => {
      if (message === "OK") {
        toast("Payment was successful.");
        handleSubmitLoanEdit();
      }
    });
  }, [handleSubmitLoanEdit, socket]);

  return (
    <Grid container justifyContent="center">
      {showEditModal && (
        <EditLoanModal
          open={showEditModal}
          closeModalHandler={handleCloseTermsModal}
          submitCallback={handleSubmitLoanEdit}
          revise={revise}
          PaymentComponent={
            revise && (
              <StripePayments
                submitCallback={handleSubmitLoanEdit}
                payeerRole="borrower"
                revise={revise}
                requestedAmount={requestState?.requestedAmount}
              />
            )
          }
        />
      )}
      <LoanRequestForm
        requestState={requestState}
        setRequestState={setRequestState}
        formTitle="Update Loan Request"
        cancelClickHandler={() => cancelLoanEditingHandler(state.loanId)}
        submitButtonTitle="Update Request"
        submitCallback={() => setShowEditModal(true)}
        isLoanEditing={true}
        enableCollateral={!!requestState?.collateral?.title}
      />
    </Grid>
  );
};

export default EditRequest;
