import React, { memo, useCallback, useMemo, useState } from "react";

import { Button, Grid, TextField, Typography } from "@mui/material";

import { UploadButton } from "./UploadButton";
import { toast } from "react-toastify";

export const CollateralForm = memo(
  ({ requestState, setRequestState, closeForm, setErrors, theme }) => {
    const [collateralFiles, setCollateralFiles] = useState(
      requestState.collateralFiles
    );
    const [existedCollateral, setExistedCollateral] = useState(
      requestState?.collateral
    );
    const [collateralErrors, setCollateralErrors] = useState([]);

    const allCollateralImages = useMemo(
      () => collateralFiles.concat(existedCollateral?.images || []),
      [collateralFiles, existedCollateral?.images]
    );

    const onChangeCollateral = ({ target }) => {
      const { value, name } = target;

      setExistedCollateral((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setCollateralErrors((prevState) => prevState.filter((n) => n !== name));
    };

    const handleUploadImage = useCallback(
      (e, type) => {
        const { files } = e.target;

        if (collateralFiles.length + files.length > 3) {
          return toast.info("You can upload up to 3 images");
        }
        let collateralUploads = [...collateralFiles];

        Object.values(files).forEach(async (file) => {
          if (collateralUploads.length <= 3) {
            collateralUploads.push({
              id: Math.random().toString(),
              data: file,
              type,
            });
          } else {
            toast.info("You can upload up to 3 images");
          }
        });

        setCollateralFiles(collateralUploads);
        setCollateralErrors((prevState) =>
          prevState.filter((n) => n !== "images")
        );
      },
      [collateralFiles, setRequestState]
    );

    const handleDeleteImage = useCallback(
      (imageID, imageURL) => {
        if (imageURL) {
          const updatedExistedImages = existedCollateral.images?.filter(
            (image) => image.id !== imageID
          );
          setExistedCollateral((prevState) => ({
            ...prevState,
            images: updatedExistedImages,
          }));
        } else {
          const updatedImageFiles = collateralFiles.filter(
            (image) => image.id !== imageID
          );
          setCollateralFiles(updatedImageFiles);
        }
      },
      [collateralFiles, existedCollateral.images]
    );

    const submitCollateralForm = (e) => {
      e.preventDefault();

      if (existedCollateral.title.trim().length < 3) {
        return setCollateralErrors((prevState) => [...prevState, "title"]);
      }
      if (existedCollateral.description.trim().length < 10) {
        return setCollateralErrors((prevState) => [
          ...prevState,
          "description",
        ]);
      }

      if (
        requestState.isLoanEditing &&
        existedCollateral.images.length + (collateralFiles?.length || []) < 1
      ) {
        return setCollateralErrors((prevState) => [...prevState, "images"]);
      } else if (!requestState.isLoanEditing && collateralFiles.length < 1) {
        return setCollateralErrors((prevState) => [...prevState, "images"]);
      }
      setRequestState((prevState) => ({
        ...prevState,
        collateral: existedCollateral,
        collateralFiles,
        isCollateralExist: true,
      }));
      setErrors((prevState) => ({
        ...prevState,
        isCollateralExist: false,
      }));
      closeForm();
    };
    return (
      <Grid
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          width: "100%",
          border: "1px solid rgba(66, 103, 178,0.3)",
          boxShadow: "0px 1px 6px 0px #7C9AEA",
          borderRadius: "6px",
          padding: "1em",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: "10px", md: "8px" },
          width: "100%",
        }}
      >
        <Typography variant="h5" sx={{ color: "rgba(0,0,0,.6)" }}>
          Add Collateral
        </Typography>
        <Typography sx={{ color: "rgba(0,0,0,.6)", fontSize: "18px" }}>
          (No Paychecks are Allowed)
        </Typography>
        <Grid
          container
          item
          sx={{
            flexDirection: "row",
            padding: "5px",
            mt: "8px",
            gap: 1,
            borderRadius: "8px",
            width: "90%",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          {/* <Grid> */}
          <TextField
            required
            error={collateralErrors.includes("title")}
            helperText={
              collateralErrors.includes("title") &&
              "The size of the input data can't be less than 3"
            }
            label="Title"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "rgba(255,255,255,0.6)",
                borderRadius: "10px",
                height: "50px",
              },
            }}
            fullWidth
            name="title"
            placeholder="Title (required)"
            value={existedCollateral.title}
            onChange={onChangeCollateral}
          />
          <TextField
            required
            multiline
            rows={4}
            error={collateralErrors.includes("description")}
            helperText={
              collateralErrors.includes("description") &&
              "The size of the input data can't be less than 10"
            }
            label="Description"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "rgba(255,255,255,0.6)",
                borderRadius: "10px",
              },
            }}
            fullWidth
            name="description"
            placeholder="Description (required)"
            value={existedCollateral.description}
            onChange={onChangeCollateral}
          />

          <Grid sx={{ width: "100%" }}>
            <Typography
              sx={{
                textAlign: "left",
                color: "rgba(0,0,0,.6)",
                fontSize: "18px",
                ml: "3px",
              }}
            >
              Add up to 3 images
            </Typography>
            {!!allCollateralImages.length && (
              <Grid
                sx={{
                  border: "1px solid #ccc",
                  p: 1,
                  borderRadius: "10px",
                  width: "fit-content",
                }}
              >
                {allCollateralImages.map((image) => {
                  return (
                    <Grid
                      key={image?.originalname || image?.id}
                      item
                      sx={{
                        display: "inline-flex",
                        flexDirection: "column",
                        justifyContect: "center",
                        alignItems: "center",
                        "&:not(:first-of-type)": { marginLeft: 1 },
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "13px", color: "rgba(0,0,0,.7)" }}
                      >
                        {image?.data?.name ||
                          image?.originalname ||
                          image?.data?.originalname}
                      </Typography>
                      <Typography
                        onClick={() => handleDeleteImage(image.id, image?.url)}
                        sx={{
                          fontSize: "12px",
                          color: theme.palette.warning.main,
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        Delete
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {collateralErrors.includes("images") && (
              <Typography
                sx={{
                  color: "#d32f2f",
                  fontSize: "12px",
                  margin: "3px 0 0 3px",
                }}
              >
                At least 1 image is required
              </Typography>
            )}
            {allCollateralImages.length < 3 && (
              <UploadButton
                caption="Upload"
                handleUploadImage={handleUploadImage}
                type="collateralImages"
                styles={{
                  display: "inline-block",
                  margin: "0 auto",
                  padding: allCollateralImages?.length
                    ? "0.1rem 0.5rem"
                    : "0.5rem 1rem",
                }}
              />
            )}
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: "flex-end",
              gap: "0.5rem",
              mt: {
                xs: "1rem",
                md: 0,
              },
            }}
          >
            <Button variant="contained" color="secondary" onClick={closeForm}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={submitCollateralForm}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
